<div id="kt_header_mobile" class="header-mobile" *ngIf="user">
    <a routerLink="/superadmin" *ngIf="user.role === 'superadmin'">
        <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-default max-h-30px" />
    </a>
    <a routerLink="/verifier" *ngIf="user.role === 'verifier'">
        <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-default max-h-30px" />
    </a>
    <a routerLink="/admin" *ngIf="user.role === 'admin'">
        <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-default max-h-30px" />
    </a>
    <div class="d-flex align-items-center">
        <button class="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
      <span></span>
    </button>
        <button class="btn btn-icon btn-hover-transparent-white p-0 ml-3" id="kt_header_mobile_topbar_toggle">
      <span class="svg-icon svg-icon-xl">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
          viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
              fill="#000000" fill-rule="nonzero" opacity="0.3" />
            <path
              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
              fill="#000000" fill-rule="nonzero" />
          </g>
        </svg>
      </span>
    </button>
    </div>
</div>
<div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            
            <div id="kt_header" class="header header-fixed" *ngIf="user">
                
                <div class="container d-flex align-items-stretch ">
                    
                 <img  class="mr-1" src="./../assets/media/logo.png" >
        
                 <h2 class="title">Aligarh Muslim University</h2>
                    <div class="d-flex align-items-stretch mr-3">
                        <div class="header-logo">
                            <a routerLink="/superadmin" *ngIf="user.role === 'superadmin'">
                                <!-- <img alt="Logo" src="assets/media/logos/logo-letter-9.png" class="logo-default max-h-40px" />
                                <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-sticky max-h-40px" /> -->
                            </a>
                            <a routerLink="/verifier" *ngIf="user.role === 'verifier'">
                                <!-- <img alt="Logo" src="assets/media/logos/logo-letter-9.png" class="logo-default max-h-40px" />
                                <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-sticky max-h-40px" /> -->
                            </a>
                            <a routerLink="/admin" *ngIf="user.role === 'admin'">
                                <!-- <img alt="Logo" src="assets/media/logos/logo-letter-9.png" class="logo-default max-h-40px" />
                                <img alt="Logo" src="assets/media/logos/logo-letter-1.png" class="logo-sticky max-h-40px" /> -->
                            </a>
                        </div>
                        <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                            <div id="kt_header_menu" class="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
                                <ul class="menu-nav">
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="topbar" style="margin-left: 800px;">
                        <div class="dropdown">
                            <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
                                <div class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
                                    <span class="text-white font-weight-bold font-size-base d-none d-md-inline mr-1">Welcome!</span>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                      </svg></span>
                                    <span class="symbol symbol-35">
                    
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0" >
                                <div class="d-flex align-items-center p-8 rounded-top">
                                    <div class="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                        <img  src="./../assets/media/profile.png" >
                                    </div>
                                    <div class="navi-text">
                                        <div class="font-weight-bold">{{user.firstName}} {{user.lastName}} [{{user.username}}]</div>
                                        <div class="text-muted">{{user.email}} </div>
                                        <span class="label label-light-danger label-inline font-weight-bold">{{user.usertype |
                      titlecase}}</span>
                                    </div>
                                </div>
                                <div class="separator separator-solid"></div>
                                <div class="navi navi-spacer-x-0 pt-5">
                                    <div class="navi-separator mt-3"></div>
                                    <div class="navi-footer px-8 py-5">
                                        <a (click)="logout()" class="btn btn-light-primary font-weight-bold">Sign Out</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <alert></alert>
            <router-outlet></router-outlet>
            <br>
            <div class="footer  py-4 d-flex flex-lg-column" id="kt_footer">
                <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="text-dark order-2 order-md-1">
                        <span class="text-white font-weight-bold mr-2">©2023 Developed at Aligarh Muslim University</span>
                    </div>
                    <div class="nav nav-dark order-1 order-md-2">
                        <span class="nav-link  text-white pr-3 pl-0">Powered by Controller of Examinations</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- NINGEG28b730test1@amucontrollerexams.com -->