import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { AccountService, AlertService } from '@app/_services';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.less']
})
export class DownloadComponent implements OnInit {
  user: User;
  courseMap = new Array();
  sample: { value: string; onclick: string; }[];
  arr = new Array
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    Parse.serverURL = environment.serverURL;
    if (this.accountService.userValue) {
      this.user = this.accountService.userValue;
    }
    route.params.subscribe(params => {
      if(params['role'] === 'superadmin'){
        this.pouplateCourse();
      } else {
        this.pouplateCourseMap(params['role']);
      }
    });
  }
  async pouplateCourse(){
    const CourseMap = Parse.Object.extend('CourseMap');
    const query = new Parse.Query(CourseMap);
    query.limit(10000);
    this.courseMap = await query.find();
  }
  async pouplateCourseMap(code){
    const cm = Parse.Object.extend('CourseMap');
    const query = new Parse.Query(cm);
    query.equalTo('cCode', code);
    query.limit(10000);
    this.courseMap = await query.find();
  }
  async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);
    this.sample = [
      { "value": "New", "onclick": "CreateNewDoc()" },
      { "value": "Open", "onclick": "OpenDoc()" },
      { "value": "Close", "onclick": "CloseDoc()" }
    ]

  }
  selectedCourse = '';
  from='';
  to='';
  // async download(status) {
  //   if (this.selectedCourse === '') {
  //     alert('Kindly select required input');
  //   } else {
  //     if(this.from)
  //     var from = new Date(this.from);
  //     if(this.to)
  //     var to = new Date(this.to);
  //     //console.log(from);
  //     //resubmit
  //     //resetpay
  //     //open_paymentt  cCode
  //     if (confirm('Do you want to resetpay?'+ this.selectedCourse)) {
  //       try {
  //        const flag= await Parse.Cloud.run('resetpay', { 'data': {code:this.selectedCourse } }, {
  //     success: function (s) {
  //       console.log(s)
 
  //     }, error: function (error) {

  //     }
  //   })
  //        // setTimeout(() => this.downloadFile(flag), 1000);
  //       } catch (error) {
  //         alert(error);
  //       }
  //     }
  //   }
  // }
  
  async download(status) {
    if (this.selectedCourse === '') {
      alert('Kindly select required input');
    } else {
      if(this.from)
      var from = new Date(this.from);
      if(this.to)
      var to = new Date(this.to);
      //console.log(from);
      if (confirm('Do you want to download?')) {
        try {
          const flag = await Parse.Cloud.run('downloadCsv_id', { 'data': { status: status, cCode: this.selectedCourse, from: from, to: to } }, {
            success: function (s) {
              console.log(s)

            }, error: function (error) {

            }
          })
          setTimeout(() => this.downloadFile(flag), 1000);
        } catch (error) {
          alert(error);
        }
      }
    }
  }

  async sync(cCode, status) {
    const d = new Date();
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * +5.5));
    nd.setHours(0, 0, 0, 0)
    this.selectedCourse = cCode;
    if (confirm('Do you want to sync paymetn status for this course?')) {
      try {
        const flag = await Parse.Cloud.run('postpayverify', { 'data': { code: cCode, date: '13-07-2021' } }, {
          success: function (s) {
            console.log(s)

          }, error: function (error) {

          }
        })
        alert('success')
      } catch (error) {
        alert(error);
      }
    }
  }


  async resetpay(cCode, status) {
    this.selectedCourse = cCode;
    if (confirm('Do you want to reset payment status for this course who are allowedtopay?'))
      if (confirm('Do you want to reset payment status for this course who are allowedtopay?')) {
        try {
          const flag = await Parse.Cloud.run('resetpayy', { 'data': { code: cCode } }, {
            success: function (s) {
              console.log(s)

            }, error: function (error) {

            }
          })
          alert('success')
        } catch (error) {
          alert(error);
        }
      }
  }

  downloadFile(data: any) {
    console.log(data[0])
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.selectedCourse + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
