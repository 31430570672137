import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/_services';

@Component({
  selector: 'app-executive',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.less']
})
export class ExecutiveComponent implements OnInit {

  constructor(private accountService: AccountService) { }
  student: any;
  ngOnInit(): void {
  }
  async findStudent(email) {
    const found = await this.accountService.findStudent(email).then();
    console.log(found);
  }
}
