import { Component, OnInit, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import * as Parse from 'parse';
import { AccountService, AlertService, SocialService } from '@app/_services';
import { User } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GoogleLoginProvider, AuthService, FacebookLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isSocialLogin = false;

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    // private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private authService: AuthService,
    private socialService: SocialService
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    Parse.serverURL = environment.serverURL;
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }
  public get userValue(): User {
    return this.userSubject.value;
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  async onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    try {
      const data = await this.accountService.login(this.f.username.value, this.f.password.value)
        .then();
      if (data.role === 'superadmin') {
        this.router.navigate(['/superadmin']);
      } else if (data.role === 'verifier') {
        this.router.navigate(['/verifier']);
      } else {
        this.router.navigate([this.returnUrl]);
      }
    } catch (error) {
      this.alertService.error(error);
      this.loading = false;
    }

  }
  // async signInWithGoogle() {
  //     try {
  //         const user = await Parse.FacebookUtils.logIn();
  //         if (!user.existed()) {
  //             alert("User signed up and logged in through Facebook!");
  //         } else {
  //             alert("User logged in through Facebook!");
  //         }
  //     } catch (error) {
  //         alert("User cancelled the Facebook login or did not fully authorize.");
  //     }
  // }
  // signInWithGoogle() {
  //     this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //     this.authService.authState.subscribe(async (user) => {
  //         try {
  //             if (user) {
  //                 const query = new Parse.Query(Parse.User);
  //                 query.equalTo('username', user.email);
  //                 const result = await query.find();
  //                 if (!result) {
  //                     const data = new User();
  //                     data.token = user.authToken;
  //                     data.firstName = user.firstName;
  //                     data.lastName = user.lastName;
  //                     data.username = user.email;
  //                     data.id = user.id;
  //                     const parseUser = new Parse.User();
  //                     await parseUser.linkWith('google', { authData: data });
  //                     localStorage.setItem('user', JSON.stringify(data));
  //                     this.userSubject.next(data);
  //                     this.isSocialLogin = true;
  //                     //this.router.navigate([this.returnUrl]);
  //                     this.router.navigate(['../login'], { relativeTo: this.route });
  //                 } else {
  //                     const data = new User();
  //                     data.token = user.authToken;
  //                     data.firstName = user.firstName;
  //                     data.lastName = user.lastName;
  //                     data.username = user.email;
  //                     data.id = user.id;
  //                     localStorage.setItem('user', JSON.stringify(data));
  //                     this.userSubject.next(data);
  //                     this.isSocialLogin = true;
  //                     // this.router.navigate([this.returnUrl]);
  //                     this.router.navigate(['../login'], { relativeTo: this.route });
  //                 }
  //             }
  //         } catch (error) {
  //             this.alertService.error(error);
  //         }
  //     });
  // }
  // signInWithFB() {
  //     this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  //     this.authService.authState.subscribe(async (user) => {
  //         try {
  //             if (user) {
  //                 const query = new Parse.Query(Parse.User);
  //                 query.equalTo('username', user.email);
  //                 const result = await query.find();
  //                 if (!result) {
  //                     const data = new User();
  //                     data.token = user.authToken;
  //                     data.firstName = user.firstName;
  //                     data.lastName = user.lastName;
  //                     data.username = user.email;
  //                     data.id = user.id;
  //                     const parseUser = new Parse.User();
  //                     await parseUser.linkWith('facebook', { authData: data });
  //                     localStorage.setItem('user', JSON.stringify(user));
  //                     this.userSubject.next(data);
  //                     this.isSocialLogin = true;
  //                     //this.router.navigate([this.returnUrl]);
  //                     this.router.navigate(['../login'], { relativeTo: this.route });
  //                 } else {
  //                     const data = new User();
  //                     data.token = user.authToken;
  //                     data.firstName = user.firstName;
  //                     data.lastName = user.lastName;
  //                     data.username = user.email;
  //                     data.id = user.id;
  //                     localStorage.setItem('user', JSON.stringify(data));
  //                     this.userSubject.next(data);
  //                     this.isSocialLogin = true;
  //                     //this.router.navigate([this.returnUrl]);
  //                     this.router.navigate(['../login'], { relativeTo: this.route });
  //                 }
  //             }
  //         } catch (error) {
  //             this.alertService.error(error);
  //         }
  //     });
  // }
  async signInWithGoogle() {
    try {
      const data = await this.socialService.signInWithG().then();
      if (data) {
        this.isSocialLogin = true;
        this.router.navigate([this.returnUrl]);
        console.log(this.returnUrl);
        console.log(data);
        const user = new Parse.User();
        user.set('firstName', data.firstName);
        user.set('lastName', data.lastName);
        user.set('username', data.username);
        user.set('password', data.username);
        try {
          await user.save();
          // tslint:disable-next-line: max-line-length
          this.alertService.success('We have created your profile. Please see your mail for more details', { keepAfterRouteChange: true });
          this.router.navigate(['../login'], { relativeTo: this.route });

        } catch (error) {
          this.alertService.error(error);
          this.loading = false;
        }
      }
    } catch (error) {
      this.alertService.error(error);
      this.loading = false;
    }

  }
  async signInWithFB() {
    try {
      const data = await this.socialService.signInWithF().then();
      if (data) {
        this.isSocialLogin = true;
        this.router.navigate([this.returnUrl]);
        console.log(this.returnUrl);
        console.log(data);
        const user = new Parse.User();
        user.set('firstName', data.firstName);
        user.set('lastName', data.lastName);
        user.set('username', data.username);
        user.set('password', data.username);
        try {
          await user.save();
          this.alertService.success('We have created your profile. Please see your mail for more details', { keepAfterRouteChange: true });
          this.router.navigate(['../login'], { relativeTo: this.route });

        } catch (error) {
          this.alertService.error(error);
          this.loading = false;
        }
      }
    } catch (error) {
      this.alertService.error(error);
      this.loading = false;
    }
  }

}
