import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { environment } from '@environments/environment';
import { RxwebValidators, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as Parse from 'parse';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit { user: User;
  form: FormGroup;
  loading = false;
  submitted = false;
  file: any;
  moderation = new Array();
  documentsCheck = [
    'High School or Equivalent',
    'Intermediate or Equivalent/Diploma',
    'Graduation',
    'Masters',
    'Others',
    'Special Category'
  ];
  username: any;
  code: any;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    Parse.serverURL = environment.serverURL;
    if (this.accountService.userValue) {
      this.user = this.accountService.userValue;
    }
  }

  async ngOnInit(): Promise<void> {
    const data = await this.accountService.getAllAssignedStudents(this.user.from_rollNo, this.user.to_rollNo, this.user.cCode).then();
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const item = {
          name: data[i].get('name'),
          roll_no: data[i].get('roll_no'),
          status: data[i].get('status'),
          moderation: data[i].get('moderation'),
          code: data[i].get('cCode'),
          cCode: data[i].get('cCode'),
          username: data[i].get('username'),
          uploads: data[i].get('docurl'),
          preferences: data[i].get('preferences'),
          vStatus: data[i].get('vStatus'),
          verifiedBy: data[i].get('verifiedBy')
        };
        this.moderation.push(item);
      }
    }
  }
  reason: any;
  async approve(reason) {
    // if (confirm('Are you sure, really want to verify these details?')) {
    //   try {
    //     const flag = await Parse.Cloud.run('approve', { 'data': { username: this.username, code: this.code, reason: reason } }, {
    //       success: function (s) {

    //       }, error: function (error) {

    //       }
    //     });
    //     window.location.reload();
    //     if (flag === 'success') {
    //       alert('Successfully verified');
    //       window.location.reload;
    //     }
    //   } catch (error) {
    //     alert(error);
    //     this.loading = false;
    //   }
    // }
  }
  rejectt(username, code,) {
    this.username = username;
    this.code = code;

  }
  approvee(username, code,) {
    this.username = username;
    this.code = code;

  }


  

  async reject(reason) {
    // if (reason === '') {
    //   alert('Please mention the reason for rejection');
    // } else {
    //   if (confirm('Are you sure, really want reject?')) {
    //     try {
    //       const flag = await Parse.Cloud.run('reject', { 'data': { username: this.username, code: this.code, reason: reason } }, {
    //         success: function (s) {

    //         }, error: function (error) {

    //         }
    //       });


    //       window.location.reload();
    //       if (flag === 'success') {
    //         alert('Rejected and reason has been recorded');
    //         window.location.reload;
    //       }
    //     } catch (error) {
    //       alert(error);
    //       this.loading = false;
    //     }
    //   }
    // }
  }
  async reset(username, code) {
    // if (confirm('Are you sure, really want reset?')) {
    //   try {
    //     const flag = await Parse.Cloud.run('reset', { 'data': { username: username, code: code } }, {
    //       success: function (s) {

    //       }, error: function (error) {

    //       }
    //     });
    //     window.location.reload();
    //     if (flag === 'success') {
    //       alert('Reset has been recorded');
    //       window.location.reload;
    //     }
    //   } catch (error) {
    //     alert(error);
    //     this.loading = false;
    //   }
    // }
  }
  getStatusClass(status) {
    if (status === 'open') {
      return 'label label-info label-pill label-inline mr-2';
    } else if (status === 'submitted') {
      return 'label label-warning label-pill label-inline mr-2';
    } else if (status === 'verified') {
      return 'label label-success label-pill label-inline mr-2';
    } else if (status === 'rejected') {
      return 'label label-danger label-pill label-inline mr-2';
    } else if (status === 'completed') {
      return 'label label-primary label-pill label-inline mr-2';
    } else if (status === 'allowedtopay') {
      return 'label label-secondary label-pill label-inline mr-2';
    }
    else if (status === 'closed') {
      return 'label label-secondary label-pill label-inline mr-2';
    }
    else if (status === 'approved') {
      return 'label label-success label-pill label-inline mr-2';
    }
  }
  pv: any = {};
  popverify(item) {
    console.log(item)
    this.pv = {};
    this.pv = item;

  }
 
}

