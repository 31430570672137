<div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-9 mx-auto">
      <div class="card card-signin my-5 mt-40 mb-20">
        <div class="card-body">
          <h5 class="card-title text-center h1">Sign In</h5>
          <br>
          <br>
          <img  class=" mb-3  "src="./../../assets/media/bg/amu-banner-4.png" height="100%" width="100%" alt="Girl in a jacket">

          <!-- <h1 class="text-dark mb-3 text-center ">Aligarh Muslim University</h1> -->
          <br><br>
          <form class="form-signin" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-label-group">
              <label class="h6" for="username">Username</label>
              <input type="text" formControlName="username" class="form-control" id="username"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Email address" required
                autofocus> 
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
                </div>             
            </div>
            <div class="form-label-group mt-3">
              <label  class="h6"for="password">Password</label>
              <input type="password" formControlName="password" class="form-control" id="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="customCheck1">
              <label class="custom-control-label" for="customCheck1">Remember password</label>
            </div>
            <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary btn-block text-uppercase">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
          </form>
          <!-- <a routerLink="../register" class="btn btn-link">Register</a>
          <hr class="my-4">
          <button class="btn btn-lg btn-google btn-block text-uppercase" (click)="signInWithGoogle()">
            <i class="fab fa-google mr-2"></i> Sign in with Google</button>
          <button class="btn btn-lg btn-facebook btn-block text-uppercase" (click)="signInWithFB()">
            <i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-9 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">Sign In</h5>
          <div class="form-signin">
            <div class="form-label-group">
              <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
              <label for="inputEmail">Email address</label>
            </div>

            <div class="form-label-group">
              <input type="password" id="inputPassword" class="form-control" placeholder="Password" required>
              <label for="inputPassword">Password</label>
            </div>

            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="customCheck1">
              <label class="custom-control-label" for="customCheck1">Remember password</label>
            </div>
            <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
            <hr class="my-4">
            <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign in with Google</button>
            <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-9 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">Sign In</h5>
          <div class="form-signin">
            <div class="form-label-group">
              <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
              <label for="inputEmail">Email address</label>
            </div>

            <div class="form-label-group">
              <input type="password" id="inputPassword" class="form-control" placeholder="Password" required>
              <label for="inputPassword">Password</label>
            </div>

            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="customCheck1">
              <label class="custom-control-label" for="customCheck1">Remember password</label>
            </div>
            <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
            <hr class="my-4">
            <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign in with Google</button>
            <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->