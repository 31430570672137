import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '@app/_services';
import { environment } from '@environments/environment';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as Parse from 'parse';
@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.less']
})
export class HelpdeskComponent implements OnInit{
  rows = [];

  temp = [];

  columns = [{ prop: 'name' }, { name: 'Company' }, { name: 'Gender' }];
  @ViewChild(DatatableComponent) table: DatatableComponent;

  ColumnMode = ColumnMode;

  constructor(private accountService: AccountService) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_APP_ID);
    Parse.serverURL = environment.serverURL;
 
  }
  async ngOnInit(){
    console.log("---------------")
    this.rows = await this.accountService.getAllVerifiedStudents().then();
    console.log(this.rows);
  }
  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/data/company.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
