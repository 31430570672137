import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@app/_services';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import * as $ from 'jquery';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';


(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
var useremail:any
var final_list=new Array()

//@ts-ignore
import { Parse } from 'parse';
import {  FormControl, } from '@angular/forms';
import { async,  } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration-paper',
  templateUrl: './registration-paper.component.html',
  styleUrls: ['./registration-paper.component.less']
})
export class RegistrationPaperComponent implements OnInit {

  
  myimage!: Observable<any>;
  photo_url:any;
 
  
  
  form: FormGroup;
  
  
  file: any;
  extensions = new Array();
  
  documents = [
  'Photo',
  'Signature',
  
  ];
 
  
 //  documentsCheck = [
 //  'High School or Equivalent',
 //  'Intermediate or Equivalent/Diploma',
 //  'Graduation',
 //  'Masters',
 //  'Others',
 //  'Special Category'
 //  ];
 
  src: string;
  imageSrc: string;
  doc: any;
  closeResult: string;
  itemss = new Array();
  //
 select:any
   Assistant_url: string;
   paper_arrC = new Array();
   paper_arr = new Array();
   paper_length: any;
   CourseForm: FormGroup;
   details: any;
   isLoading$: Observable<boolean>;
   hasError: boolean;
   err: any;
   submitted = false;
   loading = false;
   // courses = [
   //   { Course: this.user.course, Registration_type: 'Regular', session: '2020-21', season: 'even' },
  
   // ];
   CourseDetails: any = [];
   mySubscription: any;
   data_P: any;
   user: any;
   uploads = new Array();
   signurl: any;
   userimg: any;
 
   sign_url: string;
   image_url: string;
   loadings: boolean;
   load: boolean;
   Assistant_sign: string;
   flag: boolean;
   pframe_url: string;
   pFrame: string;
   time: string;
   gender: any;
   flag_g: boolean;
   Logo_url: string;
 
   sig={id:String,
  url:String};
  pho={id:String,
    url:String};
   pho_flag:boolean;
   sig_flag:boolean;
   btn1: number;
   menu_btn:number
   btn2: number;
   courses=new Array()
   pay_flag=false;
 response:any;
 addForm: FormGroup;
 addForm1: FormGroup;
 razorpayResponse:any;
 showModal = false;
  submit_flag=true;
  
  Compare = [
    { value: 'startsWith', name: 'Starts With' },
    { value: 'equals', name: 'Equals' },
    { value: 'endsWith', name: 'Ends With' },
  ];
  compare = this.Compare[0].value;
   CompareWith = [
    { value: 'subtt', name: 'Subject Name' },
    { value: 'subcd', name: 'Subject Code' },
    { value: 'Enroll', name: 'Enrollment' },
  ];
  compareWith = this.CompareWith[0].value;

  
  search = '';
  types = ['Main or Subsidary', 'Compulsory'];
  modes = [   'A', 'B' , 'C' ];
  subjects: any = [];
  
  serachLoading = false;
  myCourseLoading = false;

  type:any;

  today: string;
  
  subject: any;
  Papers=[];
  allsubject:any=[]
  allVoc:any=[]
  paper: any;
  pap:any=[];
  addpaper: boolean;
  data: any;
 
  checkType:any=[];
  vac_tag: boolean;
  voc_tag: boolean;
  allVac:any=[];
  papVac:any=[];
  //useremail:any;

 
 

  param1 = ['cCode', 'name', 'roll_no', 'username', 'status', 'gender', 'enrolment', 'faculty_no', 'cCode_offered', 'email', 'mobile', 'app_no'];
  param2 = ['endsWith', 'startsWith', 'equals', 'exists'];
  params = { param1: '', param2: '', param3: '' };
  fecthing = false;
  fecthingE = false;
  fetched = false;
  fetchedE = false;
  lockList = [];
  labels = [
    'Sr. No.',
    'Name',
    'Course Code',
    'Course Name',
    'Roll Number',
    'Status',
    'Submitted',
    'Unique ID',
  ];
  labelsE = [
    'Sr. No.',
    'Name',
    'Mother Name',
    'Course Code',
    'Course Name',
    'Roll Number',
    'App No',
    'DOB'
  ];
  Register_Paper= [];
  loader: boolean;
  constructor(private accountService: AccountService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder, private route: ActivatedRoute,) {

      this.enrolment = this.route.snapshot.params['id'];

      // console.log(this.enrolment)
      this.getSubject()
       this.get_details()
       this.update()
    if (this.accountService.userValue) {
      this.user = this.accountService.userValue;
    }
  }


   
 async ngOnInit(): Promise<void> {

  
  this.form = this.fb.group({
    list: ['', [RxwebValidators.required(),
    RxwebValidators.extension({
    extensions: ['pdf', 'png', 'jpeg', 'gif', 'jpg'], message: 'Only Image (png, jpeg, gif, jpg)/PDF file is allowed'
    }), RxwebValidators.fileSize({ maxSize: 2e+6, message: 'Uploading file size cannot exeed 2 mb' }),
    ]],
    type: ['', Validators.required],
    label: ['']
    });

  this.initForm();
  this.initForm1();


 
   const voc=await this.accountService.getEligibleFyp()
  //  console.log()


  

   for(let i in voc){

     const paper = {
      ...voc[i].attributes,
      id: voc[i].id,
    };
  
    this.allVoc.push(paper)
   
  }
  // console.log(this.allsubject)
  for (let i = 0; i < this.allVoc.length; i++) {
    this.pap.push(this.allVoc[i])
   
  }
    //  console.log(this.allVoc)
  // console.log(this.allsubject)


const vac = await this.accountService.getEligiblevac()
//  console.log(vac)




for(let i in vac){

  const paper = {
   ...vac[i].attributes,
   id: vac[i].id,
 };

 this.allVac.push(paper)

}
//  console.log(this.allVac)
for (let i = 0; i < this.allVac.length; i++) {
 this.papVac.push(this.allVac[i])

}

 const userP = Parse.User.current();

    this.user = {
      ...userP.attributes,

      id: userP.id
    };
    useremail=this.user.email
    //console.log(this.user)

  }



  async Registration_paper() {
    this.Register_Paper=[]
    this.lockList = [];
    this.fecthing = true;
    this.fetched = false;
    this.params.param3 = this.params.param3.toUpperCase();
    const data = await this.accountService._search(this.params).then();

 
    if (data) {
      this.fecthing = false;
      this.fetched = true;
      for (let i = 0; i < data.length; i++) {
        this.Register_Paper.push(data[i].attributes);
      }
    }

    // console.log(this.Register_Paper)
  }




enrolment:any
  registration_Profile:any={};
//   openProfileRegistration(profile){
 
// this.registration_Profile=profile

// // console.log(this.registration_Profile)
// this.enrolment=this.registration_Profile.enrolment
// // console.log(this.enrolment)
// this.getSubject()
// this.get_details()
// this.update()
//  }

async get_details(){


  
  this.Logo_url = "assets/media/logos/logo4.png"
  this.getBase64LogoFromURL(this.Logo_url)
     this.flag = false;
  this.pframe_url="/assets/media/Pframe.png"
  this.getBase64pFrameFromURL(this.pframe_url);
  this.Assistant_url = "/assets/media/sign_fareed-removebg-preview.png"
  this.getBase64AssistantsignFromURL(this.Assistant_url)
  const userP = Parse.User.current();

  this.user = {
    ...userP.attributes,

    id: userP.id
  };
  // this.update();
  this.paper_arr = []

  this.paper_arrC.push(["{ text: 'paperTitle', style: 'tableHeader' }", "{ text: 'paperCodes', style: 'tableHeader' }", "{ text: 'mode', style: 'tableHeader' }", "{ text: 'credits', style: 'tableHeader' }"])
  this.courses = [
    { Course: this.user.course, 'enrol' :this.user.enrol , 'faculty_no' :this.user.faculty_no , 'sem' :this.user.sem ,Registration_type: 'Regular', session: '2022-23', season: 'odd' },
 
  ];
  const getPdf = await Parse.Cloud.run('getRegistrationData_helpdesk', {  "enrolment": this.enrolment }, {

    success: function (s: any) {


    }, error: function (error: any) {


    }

  })
  // console.log(getPdf)
  if (getPdf && getPdf.attributes.gender) {
    this.flag_g=true

  }
  if (getPdf) {

    this.flag = true
    this.data_P = {
      ...getPdf.attributes,

      id: getPdf.id
    };
    // console.log(this.data_P)
    for (var val of this.data_P.papers) {

      this.paper_arr.push(val)

    }
    
  }
  else {
    this.flag = false
  
  }


 

  this.paper_length = this.paper_arr.length
 // console.log(this.paper_length)

  // this.authService
  //   .get_particulars()
  //   .then(
  //     (details) => {
  //       const user = {
  //         ...details.attributes,
  //         id: details.id
  //       };
  //       this.initForm(user);
  //     },

  //   );

   
}
 


  getStatusClass(type: string) {


    // console.log(type)
    if (type == 'Value Added') {
      return 'badge badge-danger text-wrap mr-2';
    }
    if (type == 'Vocational') {
      return 'badge badge-success text-wrap mr-2';
    } 

    
  }



initForm(){
  this.addForm = this.fb.group({
    select1: [
      '',
      Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Zs]*$'),
      ]),
    ]
   
  });



}

initForm1(){
  this.addForm1 = this.fb.group({
   
    select2: [
      '',
      Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Zs]*$'),
      ]),
    ],

  });



}


onSubmit(){

  const value1=this.addForm.value.select1
  
  

  let data = this.allVac.filter((paper: { subtt: any; })=> paper.subtt===value1);
  //  console.log(data[0])

  if(value1===null){

    // this.notify.showError('Please Select Paper ','')
    return

  }

  // console.log(value1)

  // if(value1==null|| value2==null){
  //   this.notify.showError('Please Select Paper ','')
  //   return



  // }

  if(value1 !=='' ){
    


    
    this.accountService.registrationPapersData('', data[0], 'upload','Value Added',this.enrolment).then((data) => {
        // console.log(data)
        
      this.getSubject();
    });


    // this.notify.showSuccess('Paper Added Successfully ','')
    this.btn1=1
    
    this.addForm.get('select1')?.reset()
  
    

 
   
  }else{
    // this.notify.showError('Please Select Paper ','')
   
  }
   
  
   
    
    // this.notify.showSuccess('Your Form  has been Successfully Submitted', '');
  
  
   
    // this.notify.showError('Some required fields are missing', '');


  
}








onSubmit1(){

  
  const value2=this.addForm1.value.select2

  let data = this.allVoc.filter((paper: { subtt: any; })=> paper.subtt===value2);
  // console.log(data[0])

  if(value2===null){

    // this.notify.showError('Please Select Paper ','')
    return

  }

  // console.log(value2)

  // if(value1==null|| value2==null){
  //   this.notify.showError('Please Select Paper ','')
  //   return



  // }

  if( value2 !==''  ){
    


    
    this.accountService.registrationPapersData('', data[0], 'upload','Vocational',this.enrolment).then((data) => {
        
        // console.log(data)
      this.getSubject();
    });


    // this.notify.showSuccess('Paper Added Successfully ','')
    this.btn2=1
    
    
    this.addForm1.get('select2')?.reset()
    

 
   
  }else{
    // this.notify.showError('Please Select Paper ','')
   
  }
   
  
   
    
    // this.notify.showSuccess('Your Form  has been Successfully Submitted', '');
  
  
   
    // this.notify.showError('Some required fields are missing', '');


  
}








async update() {

  //  console.log(this.pho)
  //  console.log(this.sig)
  const clItems = await Parse.Cloud.run('getUploads_helpdesk', { 'data': {"enrolment":this.enrolment , typo: 'get' } }, {

    success: function (s: any) {


    }, error: function (error: any) {

    }
  })
  // console.log(clItems)
  for (let j = 0; j < this.documents.length; j++) {
    const doc: any = {
      type: this.documents[j],
      items: [],
      id: 'doc' + j
    };
    for (let i = 0; i < clItems.length; i++) {
      if (this.documents[j] === clItems[i].attributes.type) {
        doc.items.push({ id: clItems[i].id, url: clItems[i].attributes.url, label: clItems[i].attributes.label, at: clItems[i].attributes.createdAt });
      }
      // console.log(clItems[i])
      if (clItems[i].get("type") === 'Photo')
        this.userimg = clItems[i].get("url")
      if (clItems[i].get("type") === 'Signature')
        this.signurl = clItems[i].get("url")
    }
    this.uploads.push(doc);
  

    this.cd.detectChanges()
    // console.log(this.uploads)

  }
  if(this.uploads[0].items && this.uploads[0].items[0]){
    this.pho={id:this.uploads[0].items[0].id,
    
      url:this.uploads[0].items[0].url,}
   
    
    this.pho_flag=true
    //  console.log(this.pho.id)
    //  console.log(this.pho.url)
   
  
    this.cd.detectChanges()
    
  }else{
    this.pho_flag=false
    this.cd.detectChanges()

  }
  if(this.uploads[1].items&&this.uploads[1].items[0]){
    
    this.sig={id:this.uploads[1].items[0].id,
    
    url:this.uploads[1].items[0].url}
    this.sig_flag=true
//  console.log(this.sig.id)
//  console.log(this.sig.url)
    this.cd.detectChanges()
    
  }else{

    this.sig_flag=false
    this.cd.detectChanges()
  }
  this.getBase64signFromURL(this.signurl)
  this.getBase64ImageFromURL(this.userimg)
  
  
//  console.log(this.pho_flag)
//    console.log(this.sig_flag)
  

}









 
  async  submitPapers(obj:any) {
    // console.log(obj)
    // this.submit_flag=false;
    // this.myCourseLoading=false;

//console.log(obj)

 this.pay_flag=true

try {
  if (true) {
    if (
      confirm(
        'Are you sure to submit your Papers. Once submitted you may not be able to modify your details.'
      )
    ) {
      await Parse.Cloud.run('postPapers_helpdesk', { 'data':obj,'enrolment':this.enrolment }, {

        success: function (s: any) {
       
          
  
        }, error: function (error: any) {
  
  
        }
  
      }).then( (data:any) => {
        if (data === 're-saved') {
          // //@ts-ignore
          // toastr.info(
          //   'Counselling information for this course re-saved',
          //   'Successfully saved again'
          // );
          Parse.Cloud.run(
            'sendmailpop',
            {
              data: {
                to:useremail,
                text:
                  'Your choice for papers ' +
                   
                  ' has been re-saved.Your selected papers are as below \n\n' +
                  final_list.join('\n') +
                  '\n\n In case you wish to modify the particulars, You can visit the counselling portal before deadline.',
                subject: 'Particulars Successfully saved again',
              },
            },
            {
              success: function (savedApartment:any) { },
              error: function (error:any) { },
            }
          );
          alert('Particular successfully saved again Plesae check your Email')
          //  this.notify.showSuccess('Particular successfully saved again Plesae check your Email','')
          

          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
         else if (data === 'saved') {
          //@ts-ignore
          // toastr.success(
          //   'Counselling information for this course submitted',
          //   'Successfully submitted'
          // );
          Parse.Cloud.run(
            'sendmailpop',
            {
              data: {
                to: useremail,
                text:
                  'Your choice for papers ' +
                  
                  ' has been saved.Your selected papers are as below \n\n' +
                  final_list.join('\n') +
                  '\n\n In case you wish to modify the particulars, You can visit the counselling portal before deadline.',
                subject: 'Particulars Successfully saved ',
              },
            },
            {
              success: function (savedApartment:any) { },
              error: function (error:any) { },
            }
          );
          this.pay_flag=true
        //  this.notify.showSuccess('Particular successfully saved Plesae check your Email','')
         setTimeout(function () {
          window.location.reload();
        }, 3000);
          

       

          // setTimeout(function () {
          //   window.location.reload();
          // }, 3000);
        } 
        else if (data === 'Limit') {
          //@ts-ignore
          // toastr.success(
          //   'Counselling information for this course submitted',
          //   'Successfully submitted'
          // );
        
          this.notify.showInfo('Papers credits reached its over limit')
          

          // setTimeout(function () {
          //   window.location.reload();
          // }, 3000);
        } else {
          //@ts-ignore
          // toastr.error('Something went wrong', 'Bad Request');

          // setTimeout(function () {
          //   window.location.reload();
          // }, 3000);
        }
      });
    }
  } else {
    alert('Kindly choose upgradtion option');
  }
 
  }

 
 catch (error) {
  console.error(error);
  // this.notifyService.showError(error,'')
 
  
  return ;
}


   
  }
  //



  async getSubject() {
    const final_list=[]
    this.myCourseLoading = true;
    this.subjects = [];
    this.accountService.registrationPapersData('', '', 'get','',this.enrolment).then((subjects) => {
  //  console.log(subjects)
      for (const item of subjects) {
        const course = {
          ...item.attributes.data,
          id: item.id,
          type:item.attributes.type
        }
        final_list.push(course.subtt+'('+course.subcd+')')
        // console.log(course)
      
        //  const check=course.includes(course.subcd)

        //  if(check){
        //   alert("paper already exist")
        //   return
        //  }

        
        this.subjects.push(course);
        this.checkType=[]

        if(this.subjects.length!==0){
        
          for(let i=0; i<this.subjects.length; i++){
            this.checkType.push(this.subjects[i].type)
        
        
          }
          if(this.checkType.includes('Value Added')){

            this.btn1=1
            this.vac_tag=true
          }
        if(this.checkType.includes('Vocational')){
          this.btn2=1
          this.voc_tag=true
        }
       
        
        
        }
      }

      // console.log(this.checkType)
    
      this.myCourseLoading = false;
      this.cd.detectChanges();
      //  console.log(this.subjects)
    });

  


  }
  deleteSubject(id: string) {

    let type = this.subjects.filter((paper: { id: any; })=> paper.id===id);
    
    if (confirm('Are you sure, really want to delete this Paper?')) {
      try {
        if(type[0].type=='Value Added'){

          this.btn1=0
          this.vac_tag=false
        }
    
        if(type[0].type=='Vocational'){
    
          this.btn2=0
          this.voc_tag=false
        }
        
        
    
        this.accountService.registrationPapersData(id, '', 'del','','').then((data) => {
    
          // this.notify.showSuccess('Paper Deleted Successfully','')
          this.getSubject();
        });
      
      } catch (error) {
        // this.alertService.error(error);
        // this.notify.showError(error,'')
        // this.loading = false;
      }
    }
  


    
    // if(type[0].type!=='Value Added'&& type[0].type!=='Vocational'){

    //   confirm("Are you sure to delete right paper")
      
    // this.authService.registrationPapersData(id, '', 'del','').then((data) => {

    //   this.notify.showSuccess('Paper Deleted Successfully','')
    //   this.getSubject();
    //   return
    // });
      



    // }

   
  }
  // searchCoures() {
  //   if(!this.search){
  //     alert('Kindly enter what papers you want to search ')
  //     return
  //   }
  //   this.CourseDetails = [];
  //   this.serachLoading = true;
  //   this.authService
  //     .search_papers(this.compare, this.compareWith, this.search)
  //     .then(
  //       (CourseDetails) => {
  //          for (const iterator of CourseDetails) {
  //           const item = {
  //             ...iterator.attributes,
  //             id: iterator.id,
  //            mode: ''
  //           };
            
  //           this.CourseDetails.push(item)
            
  //         }
  //         this.cd.detectChanges();
  //         this.serachLoading = false;
  //       },
  //       (err: any) => {
  //         alert(err);
  //         this.serachLoading = false;
  //       }
  //     );
  // }




   async fetch(){

    this.accountService.getdata(this.enrolment).then((data) => {
    
      
      this.getSubject();
    });
    
    
    
    
     
    
    }



  getPdfHall() {

    
 
 
    if(this.pho_flag==false){
      alert('Please Upload Photo First')
     //  this.notify.showError('Please Upload Photo First','')
    
       
      return
      }
      if(this.sig_flag==false){
        alert('Please Upload Signature First')
       // this.notify.showError('Please Upload Signature First','')
      
        
       return
       }
      //  console.log(this.sig_flag)
      //  console.log(this.pho_flag)
    // this.notify.showSuccess("Please Wait While Your Hall Ticket Download...... ",'')
  
    // if(!this.flag_g){
    //   // this.notifyService.showError('Please Submit Gender First','')
    //   alert()
    //   return
    // }
    
    // if(!this.userimg){
    //   // this.notifyService.showError("Please Upload Photo",'')
    //   return
    // }
    // if(!this.signurl){
    //   // this.notifyService.showError('Please Upload Signature','')
      
    //   return
    // }
    // this.notifyService.showInfo(" Admit card is downloading",'')

   
    alert("Please wait while your Hall ticket downloading")

   //  this.notify.showSuccess("Please Wait While Your Admit Card Download...... ",'')
     
  // current date & Time
  var today = new Date();
  var ddd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  this.time=new Date().toLocaleTimeString();
  this.today = ddd + '/' + mm + '/' + yyyy;
 // console.log(this.today)
  //End
  this.loader=true

  
    
  
  

   
   

    setTimeout(() => {
      var dd = {
        header: {
          text: [{ text: ' Aligarh Muslim University, ALIGARH\n', fontSize: 18, bold: true, alignment: 'center' as 'center' },
          { text: 'Four Year Under Graduate Program: Session-2022-23', decoration: 'underline' as 'underline', alignment: 'center' as 'center' },

          { text: '\t(Odd Semester)', decoration: 'underline' as 'underline', alignment: 'center' as 'center', bold: true },
          ]
        },
        footer: {
          columns: [
            
            { text: '*Computer Generated Document', alignment: 'center'as'center',bold: true, fontSize: 7 }
          ]
        },
        content: [


          { text: 'HALL TICKET', decoration: 'underline' as 'underline', alignment: 'center' as 'center', bold: true },

          {

            columns: [

              {
                text: [  {text:this.data_P.course,bold:true, fontSize: 10},
               {text:'\n'+this.data_P.branch,bold:true, fontSize: 10},
                { text: '\nEnrollment No:', fontSize: 10 },
                { text: ' '+this.data_P.enrolment, bold: true, fontSize: 11 },
                { text: '\nSemester:', fontSize: 10 },
                { text: ' '+this.data_P.sem, bold: true, fontSize: 11 },
                { text: '\nFaculty No:', fontSize: 10 },
                { text:' '+ this.data_P.faculty_no, bold: true, fontSize: 11 },
               // { text: '\nHall:', fontSize: 10 },
               // { text: ' '+this.data_P.hall, bold: true, fontSize: 11 },
                { text: '\nName:', fontSize: 10 },
                { text: ' '+this.data_P.name, bold: true, fontSize: 11 }]
              },
             
              { qr: this.data_P.enrolment+' '+this.data_P.name+' '+this.data_P.course, fit: (100),
              x:38 },


              {
                image: this.image_url,
                x:10,
                width: (80),
                height: (80),
              },
              {
                image: this.pFrame,
                x:30,
                width: (80),
                height: (80),
              },
             

            ]
          },

        



          {
            style: 'tableExample',
            table: {
              widths: [215, 210, 80],
              

              body: this.buildTableBody(this.paper_arr, [{ text: 'Title of the Papers', style: 'tableHeader' }, { text: 'Paper Code', style: 'tableHeader' }, { text: 'Type', style: 'tableHeader' }]),
              headerRows: 1,

            }


          },
          
                   
          { text: '\n\nINSTRUCTIONS TO CANDIDATE', decoration: 'underline' as 'underline', bold: true },
          {
            fontSize: 8,
            ol: [
              'Paste recent passport size photograph in the box provided above and get it attested by concerned Provost',
              'Discrepancy if any, In the hall ticket,especially with regards to candidate\'s particular paper code, and/or title of the paper must be brought to the notice of the A.C (Exams) well before the scheduled date of commencement of examination',
              'Candidate will be permitted to enter into the Examination Hall only 15 Minute before the scheduled commencement of the examination.',
              'No Candidate will be permitted to enter into the Examination Hall only 15 Minute after the scheduled commencement of the examination ',
              'Candidate should occupy only the seat alloted to him/her in the Examination Hall. Valid Identity card and Hall Ticket should always be available in person and shown to inviligator as and when demanded ',
              'Mobile Phone or any other electronic gadgets is not allowed inside the Examination Hall, Non-Programmable Calculators are however Permissible, if Prescribed',
              'Estables/Beverages (except drinking water) are not allowed inside the Examination Hall',
             

              [
              ],

            ]
          },
          { canvas: [{ type: 'line' as 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2 }] },

          {
            fontSize: 8,
            ol: [
              {
                text: [

                
                  { text: 'Allowed to Appear in ' +this.paper_length+ ' Papers subject to fullfilment of attendance requirements.Student are advised to check the list of detained students from their respective Dean\'s office before appearing in the examination of the concerned paper.' },


                ]
                
              },
              'Students Appearing in the examination are governed by Examination (Control of Under Means and Disorderly Conduct) Regulations, 2019 copy of which is available on the website www.amucontrollerexam.com',


              'If the student who has not fullfiled the attendance requirement in paper appear at the end-semester/annual/Prof Examination of that paper, then the student will be apperaing at the examination in such a paper at her/his own risk and cost, and',
              {
                ul: ['Her/his Examination answer book of concerned paper will not be evaluated. If evaluated inadvertently, her/his result in the concernd paper will not be declared.',
                  'All marks obtained by the student in any component of the courses evaluation of that paper will stand cancelled.',
                  'The student will be marked as detained on account of shortage of attendance',


                ]
              },

              
              
              
            

            
              
            ]
       },
       {
        style: 'tableExample',
        table: {
          widths: [150, 120, 220],
          body: [
            ['Student\'s Signature (scanned)', 'Student\'s Signature', 'Assistant Controller (Examinations)'],
            [ {
              image: this.sign_url,
              width: (80),
              alignment: 'center' as 'center'
            }, {text: '', italics: true, color: 'gray'}, 
            {
              image: this.Assistant_sign,
              width: (80),
              alignment: 'center' as 'center'
            },]
          ]
        }
      },
      {text:'Date:'+this.today,fontSize:10 ,bold:true},
          {text:'Time:'+this.time,fontSize:10,bold:true}

         ],
        styles: {

          tableHeader: {
            bold: true,
            widths: [100, '*', 200, '*'],

          },
          tableExample: {
            fontSize: 10
          }

        }
      }

      pdfMake.createPdf(dd).download('HallTicket.pdf')
    }, 6000);
   
    setTimeout(() => {
      this.loader=false
this.menu_btn=0
      // this.loadings = false;
      this.cd.detectChanges()

    }, 6000)

  }
  //PDF section End

  buildTableBody(data: any, columns: any) {
    var body = [];

    body.push(columns);
    // console.log(data)

    data.forEach(function (row: any) {
      var dataRow: any = [];

      columns.forEach(function (column: any) {
        // console.log(column)get
        // console.log(row)
        // console.log(row[column])
        dataRow.push(row[column.text].toString());
      })

      body.push(dataRow);
      // console.log(data)

    });


    return body;

  }

  //Download Registration card start
 getPdfRegistration() {

  if(this.pho_flag==false){
   //  this.notify.showError('Please Upload Photo First','')
   alert('Please Upload Photo First')
     
    return
    }
    if(this.sig_flag==false){
     // this.notify.showError('Please Upload Signature First','')
     alert('Please Upload Signature First')
      
     return
     }
  // if(!this.flag_g){
  //   // this.notifyService.showError('Please Submit Gender First','')
    
  //   return
  // }
  
  // if(!this.userimg){
  //   // this.notifyService.showError("Please Upload Photo",'')
  //   return
  // }
  // if(!this.signurl){
  //   // this.notifyService.showError('Please Upload Signature','')
    
  //   return
  // }
    // this.notifyService.showInfo(" Registration  card is downloading",'')
// this.load=true;

  

      



   
    
   //  this.notify.showSuccess("Please Wait While Your Registration Card Download...... ",'')
  
   alert("Please wait while your Registration Card downloading")
    this.loader=true


// current date & Time
 var today = new Date();
var ddd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();
this.time=new Date().toLocaleTimeString();
this.today = ddd + '/' + mm + '/' + yyyy;
//console.log(this.today)
//End


   

    //console.log(this.data_P)

    setTimeout(() => {
      var dd = {
        header: {
          text: [{ text: ' Aligarh Muslim University, ALIGARH\n', fontSize: 18, bold: true, alignment: 'center' as 'center' },
          { text:  'Four Year Under Graduate Program: Session-2022-23', decoration: 'underline' as 'underline', alignment: 'center' as 'center' },

          { text: '\t(Odd Semester)', decoration: 'underline' as 'underline', alignment: 'center' as 'center', bold: true },
          ]
        },
        footer: {
          columns: [
            
            
            { text: '*Computer Generated Document', alignment: 'center'as'center',bold: true, fontSize: 7 }
          ]
        },




        content: [
          { text: 'Registration-cum-Examination', decoration: 'underline' as 'underline', alignment: 'center' as 'center', bold: true },

 {

            columns: [

              {
                text: [
                
                  {text:this.data_P.course,bold:true, fontSize: 10},
                  
                  {text:'\n'+this.data_P.branch,bold:true, fontSize: 10},
                { text: '\nEnrollment No:', fontSize: 10 },
                { text: ' '+this.data_P.enrolment, bold: true, fontSize: 11 },
                { text: '\nSemester:', fontSize: 10 },
                { text: ' '+this.data_P.sem, bold: true, fontSize: 11 },
                { text: '\nFaculty No:', fontSize: 10 },
                { text:' '+ this.data_P.faculty_no, bold: true, fontSize: 11 },
               // { text: '\nHall:', fontSize: 10 },
              //  { text: ' '+this.data_P.hall, bold: true, fontSize: 11 },
                { text: '\nName:', fontSize: 10 },
                { text: ' '+this.data_P.name, bold: true, fontSize: 11 }]
              },
              { qr: this.data_P.enrolment+' '+this.data_P.name+' '+this.data_P.course, fit: (100),
              x:38 },


              {
                image: this.image_url,
                x:10,
                width: (80),
                height: (80),
                
              },
              {
                image: this.pFrame,
                x:30,
                width: (80),
                height: (80),
              },
             

            ]
          },
          {text:'\n'},

           {
            style: 'tableExample',
            table: {
              widths: [215, 210, 80],

              body: this.buildTableBody(this.paper_arr, [{ text: 'Title of the Papers', style: 'tableHeader' }, { text: 'Paper Code', style: 'tableHeader' }, { text: 'Type', style: 'tableHeader' }]),
              headerRows: 1,

            }
          },
         
          {
            text: [{ text: '\n\n\n\n\n\n\nDECLARATION BY THE STUDENT:', bold: true, fontSize: 11 },
            { text: 'I hereby solemnly affirm that I have filled this Examination form myself. If it is discovered at any stage that I have made a false or incorrect statement or concealed the facts or fraudulent means have been used by me for appearing at the examination, I shall be liable to disciplinary action and my examination result may be cancelld.I further affirm that i have not taken migration Certificate from this Univeersity/Submitted the Migration Certificate. I shall abide by all the rules and regulations of the University as amended and enforced from time to time', fontSize: 10 }]
          },


          { canvas: [{ type: 'line' as 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2 }] },
          {
            text: 'Note: Student appearing in the examination are governed by Examination (Control of Unfair Means and Disorderly Conduct) Regulation, 2019 copy which is available on the website www.amucontrollerexams.com\n\n\n\n\n\n', bold: true, fontSize: 10
          },
          {
            image: this.sign_url,
            width: (80)
          },


          {
            columns: [
              { text: 'Signature of the Student\n\n\n\n\n', fontSize: 10, bold: true, },
              // {text:this.today, fontSize: 10, bold: true},

              { text: 'His Dues are clear \n\nSignature of the Provost\n(With Seal)\n\nDate......................', bold: true, fontSize: 10 },
              { text: 'Signature of the\nDEAN/PRINCIPAL/CHAIRMAN\n(With Seal)\n\nDate:............................', margin: 5, fontSize: 10, bold: true, }
            ]
          },
          {text:'Date:'+this.today,fontSize:10,bold:true},
          {text:'Time:'+this.time,fontSize:10,bold:true}
          

    
          


        ],
        styles: {

          tableHeader: {
            bold: true,
            widths: [100, '*', 200, '*'],

          },
          tableExample: {
            fontSize: 10,
            
          },
          table: {
            fontSize: 10,
            
            
          }

        }
      }

  
      pdfMake.createPdf(dd).download('Registration_Card.pdf')
    

    }, 6000);
    
    setTimeout(() => {
      this.loader=false
      this.menu_btn=0
            // this.loadings = false;
            this.cd.detectChanges()
      
          }, 6000)
   

  }
  //Download Registration card End


   
  getBase64AssistantsignFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        this.Assistant_sign = dataURL
        //console.log(dataURL)
        resolve(dataURL);
        

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });



  }



  getBase64pFrameFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        this.pFrame = dataURL
        //console.log(dataURL)
        resolve(dataURL);
        

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });



  }




  getBase64ImageFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        this.image_url = dataURL
        // console.log(this.image_url)
        resolve(dataURL);
        

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });


  }


  getBase64signFromURL(url: any) {

    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        this.sign_url = dataURL
       // console.log(this.sign_url)
        resolve(dataURL);
        

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });


  }
  typeChange() {
    if (this.form.value.type === 'Photo' || this.form.value.type === 'Signature') {
    this.extensions = [];
    this.extensions = ['png', 'jpeg', 'gif', 'jpg'];
    } else {
    this.extensions = [];
    this.extensions = ['pdf', 'png', 'jpeg', 'gif', 'jpg'];
    }
    }



    //  get f() { return this.form.controls; }



    onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
    this.file = event.target.files[0];
    }
    }

    async delete(id: any) {
      if (confirm('Are you sure want to delete?')) {
      await Parse.Cloud.run('getUploads', { 'data': { id: id, typo: 'del' } }, {
      success: function (s: any) {
      
     
     
      }, error: function (error: any) {
     
      }
      }).then(function () {
     
     
      alert('Uploaded file deleted successfully.')
     
    //  $("#btnclose").click()
      
      }, (error: any) => {
      alert(error);
      });
      setTimeout(() => {

      
        
       
       window.location.reload()
     
     }, 2000)
      
     
      // this.docItem.items.splice(j, 1);
      }
      }


  getBase64LogoFromURL(url: string) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        this.Logo_url = dataURL
       
        resolve(dataURL);
        

      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  get ff() { return this.form.controls; }
  async uploadFile() {
    this.submitted = true;
  
    

  //  this.test=this.uploads[1].items
  // console.log(this.uploads[0].items[0])
  // console.log(this.uploads)

// for(let i=0;i<this.uploads.length;i++){

//      this.test.push(this.uploads[i].items)
//    }

//     this.test1=this.test[1]
  
  // for(let i=0;i<this.test1.length;i++){

  //   this.test2.push(this.test1[i].url)
  // }
  //  console.log(this.test)
  //  console.log(this.test1)
  if (this.form.invalid) {
    return;

    }
    if (this.form.value.type === 'Photo' && (!this.uploads[0].items || this.uploads[0].items.length > 0)) {
      // this.notify.showError('Photo is already uploaded','')
    // this.loading = false;
    // $j("#close").click()
    // return
    } else if (this.form.value.type === 'Signature' && this.uploads[1].items.length > 0) {
      // this.notify.showError('Signature is already uploaded','')
    
   
    // $j("#close1").click()
    // this.loading = false;
    // return
    } 
    try {
    this.loading = true;
    // const Uploads = Parse.Object.extend('Uploads');
    const uploadfile = new Parse.File(this.file.name, this.file);
    // console.log(uploadfile)
   
    const data = await uploadfile.save().then();
    if (data) {
    const clItems = await Parse.Cloud.run('getUploads_helpdesk', { 'data': { username: this.user.username, url: uploadfile.url(), createdBy: '', type: this.form.value.type, label: this.form.value.label, typo: 'update',enrolment:this.enrolment } }, {
    success: function (s: any) {
    // console.log(s)
    }, error: function (error: any) {
   
    }
    })
    alert("upload successfully")
    // this.notify. showSuccess(this.form.value.label,' uploaded successfully')
    // window.location.reload();

    // console.log(this.form.value.label)
    
    
      setTimeout(() => {

       this.loading = false;
       $("#close1").click()
        $("#clos").click()
      this.cd.detectChanges()
      window.location.reload()
    
    }, 2000)
   
    }
    }
    catch (error) {
    //@ts-ignore
    toastr.error('Unable to upload: ' + error, 'Try Again');
    
    }
    }

    close(){

      setTimeout(() => {
        alert()

        this.update()
     
     }, 2000)

     
    }
}
