import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rectifier',
  templateUrl: './rectifier.component.html',
  styleUrls: ['./rectifier.component.less']
})
export class RectifierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
