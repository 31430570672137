import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/_models';
import { AlertService } from '@app/_services';
import { AccountService } from '@app/_services/account.service';
import { environment } from '@environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as Parse from 'parse';

@Component({
  selector: 'app-fyup-edit',
  templateUrl: './fyup-edit.component.html',
  styleUrls: ['./fyup-edit.component.less']
})
export class FyupEditComponent implements OnInit {

  user: User;
  form: FormGroup;
  form1: FormGroup;
  eVerifyForm: FormGroup;
  loading = false;
  submittedd = false;
  submitted = false;
  file: any;
  enrolment: any
  data: any;
  _data: any;
  otp: boolean;
  closeResult: string;
  loadingE: boolean;
  eVerified: boolean;
  submittedE: boolean;
  submittedM: boolean;
  mobileF: boolean;
  EmailF: boolean;
  dobF = true
  _userData
  fnameF = true
  mnameF = true
  genderF = true
  statusF: boolean;
  major = []
  minor = []
  ge = []
  va = []
  vo = []
  subject: any;

  cCodes = [
    "BMREAG",
    "ARRAAG",
    "APREAG",
    "ECROAG",
    "PAROAG",
    "ORRSAG",
    "ECRDAG",
    "ISROAG",
    "BYRLAG",
    "EEREAG",
    "PIRDAG",
    "BTRLAG",
    "STRSAG",
    "HMRGAG",
    "MURLAG",
    "ENRAAG",
    "AMRGAG",
    "SHRTAG",
    "CPRDAG",
    "MLRAAG",
    "MEREAG",
    "SWROAG",
    "URRAAG",
    "SKRAAG",
    "PLROAG",
    "HSROAG",
    "PNRAAG",
    "BNRAAG",
    "MMRSAG",
    "HRROAG",
    "WLRLAG",
    "SOROAG",
    "RSRSAG",
    "MCROAG",
    "WARIAG",
    "HNRAAG",
    "PEROAG",
    "LBROAG",
    "LWRWAG",
    "AMREAG",
    "PSROAG",
    "ZYRLAG",
    "AERGAG",
    "BCRDAG",
    "BCRLAG",
    "SSRIAG",
    "HTRAAG",
    "CMRCAG",
    "NSRDAG",
    "ACREAG",
    "CHRSAG",
    "PYRAAG",
    "GGRSAG",
    "MRRAAG",
    "MBRDAG",
    "LNRAAG",
    "CEREAG",
    "WSROAG",
    "FARAAG",
    "PHRSAG",
    "SURTAG",
    "FMRDAG",
    "GLRSAG",
    "PHRGAG",
    "ELRAAG",
    "EDROAG",
    "PRRAAG",
    "TLRAAG",
    "TMRAAG",
    "ARREAG",
    "COREAG",
    "ELREAG",
    "BARMAG",
    "CSRSAG",
    'PTCUAG',
    'BYMLAF',
    'BAAGAD',
    'ENMAAD',
    'BSCGAD',
    'BSCBAD',
    'ELMAAD',
    'MCMOAD',
    'CMBGAD',
    'BAABAD',
    'HSMOAD',
    'PLMOAN',
    'VCBXAU',
    'FDBVAU',
    'BSCGAU',
    'BAAGAU',
    'BAABAU',
    'SSSBAS',
    'CPMEAN',
    'ENMAAN',
    'QCPPAN',
    'BCMLAN',
    'BYMLAN',
    'CHMSAN',
    'FEMGAN',
    'LNMAAN',
    'ISMOAN',
    'ECMOAN',
    'MBAXAN',
    'SWMOAN',
    'GLMSAN',
    'JRPPAN',
    'MTEEAN',
    'MTLEAN',
    'CAMSAN',
    'PSMOAN',
    'ELMAAN',
    'CSMSAN',
    'MCMOAN',
    'HSMOAN',
    'ABMGAN',
    'HAMMAN',
    'URMAAN',
    'APMEAN',
    'RSMSAN',
    'DSMSAN',
    'WAMIAN',
    'CETXAL',
    'CMBGAN',
    'CMBBAN',
    'CETXAN',
    'BAAGAN',
    'BSCBAN',
    'BSCGAN',
    'DIPGAN',
    'DIPBAN',
    'ACMGAG',
    'FEMGAG',
    'WLMLAG',
    'NRBDAG',
    'PLMEAG',
    'GEMEAG',
    'PSMLAD',
    'URMAAD',
    'SOMOAD',
    'PLMOAD',
    'ELMAAD',
    'DSMSAD',
    'BSCBAD',
    'BSCGBD',
    'CMBBAD',
    'BAABRD',
    'BAAGRD',
    'BAABAS',
    'BAAGAS',
    'BCMLAS',
    'CMBBAS',
    'CMBGAS',
    'BSCBAS',
    'BSCGAS',
    'CMMCAS',
    'HSMOAS',
    'MTCXAS',
    'MFCXAS',
    'SWMOAS',
    'PSMOAS',
    'SSSBHS',
    'SSSBSS',
    'SSSBSS',
    'SSSGSS',
    'DIPBAS',
    'DIPGAS',
    'AGMGAS',
    'BTCXAE',
    'NINGEN',
    'NINBEN',
    'SIXBUN',
    'SIXGUN',
    'SIXBEN',
    'SIXGEN',
    'ONEGEG',
    'ONEBEG',
    'ONEGUG',
    'ONEBUG',
    'IBMPPA',
    'NINGEG',
    'NINBEG',
    'SIXBUG',
    'SIXGUG',
    'SIXBEG',
    'SIXGEG',
    'AMDPAG',
    'BCPPAG',
    'CLPPAG',
    'FTPPAG',
    'OPDPAG',
    'ASPPAG',
    'BEPPAG',
    'AVPPAG',
    'BLPPAG',
    'EEDPAG',
    'IMPPAG',
    'WCDPAG',
    'BAPPAG',
    'NUDPAG',
    'SEAPAG',
    'FLMGAG',
    'AGMGAG',
    'BTCXAT',
    'BSCGBN',
    'BAABRN',
    'BSCBBN',
    'BAAGRN',
    'BSCGMN',
    'BAABAN',
    'SSSGSN',
    'SSSBSN',
    'SSSGHN',
    'BSCGAN',
    'BRPCAG',
    'HRMCAG',
    'QSMAAG',
    'PLMOAG',
    'CSMSAG',
    'DSMSAG',
    'BTCXAN',
    'MBAXAN',
    'MFCXAN',
    'LLBXAN',
    'HRIRAN',
    'BAFXAG',
    'CETXAG',
    'MFCXAG',
    'BEDXAG',
    'MBAXAG',
    'BTCXAG',
    'LLBXAG',
    'DIPBAG',
    'DIPGAG',
    'SSSBSG',
    'SSSGSG',
    'SSSBAG',
    'SSSGAG',
    'VABAAG',
    'VAMAAG',
    'BSCBAG',
    'BSCBBG',
    'BSCBMG',
    'BSCGAG',
    'BSCGBG',
    'BSCGMG',
    'BAABAG',
    'BAABRG',
    'BAMBAG',
    'BAMBRG',
    'BAAGAG',
    'BAAGRG',
    'BAMGAG',
    'BAMGRG',
    'HRIRAG',
    'BRDBAG',
    'MTEEAG',
    'MTMEAG',
    'MTCEAG',
    'MTLEAG',
    'BVPTAG',
    'MSWLAG',
    'MAPLAG',
    'CMBBAG',
    'CMBGAG',
    'GNDDAG',
    'CVBEAG',
    'EVBEAG',
    'MEVEAG',
    'MVBEAG',
    'ARBEAG',
    'ARBEAN',
    'SSSBAN',
    'SSSGAN',
    'ABMGAG',
    'AGBGAG',
    'BYMLAG',
    'SWMOAG',
    'LBBOAG',
    'MCMOAG',
    'CAMSAG',
    'PTCUAG',
    'MSBUAG',
    'UTPAAG',
    'HGPSAG',
    'JRPPAG',
    'PRPPAG',
    'TXPPAG',
    'EDPPAG',
    'MUPPAG',
    'IPPPAG',
    'CCPPAG',
    'GTPPAG',
    'ECPPAG',
    'HMPPAG',
    'CTAPAG',
    'UPAPAG',
    'FYPPAG',
    'MMPPAG',
    'NDPPAG',
    'ISPPAG',
    'QCPPAG',
    'STPPAG',
    'ENPPAG',
    'HJPPAG',
    'PYMAAG',
    'LAMAAG',
    'URMAAG',
    'MCPAAG',
    'HTMAAG',
    'FABAAG',
    'ARMAAG',
    'PRMAAG',
    'SKMAAG',
    'MLMAAG',
    'BNMAAG',
    'ELMAAG',
    'ENMAAG',
    'MUPAAG',
    'FAMAAG',
    'LNMAAG',
    'HNMAAG',
    'CMMCAG',
    'RTBDAG',
    'MKMEAG',
    'CPMEAG',
    'ARMEAG',
    'PTMVAG',
    'PCMVAG',
    'FDMVAG',
    'PKMEAG',
    'NNMEAG',
    'RMMEAG',
    'PSMEAG',
    'MSMEAG',
    'IDAEAG',
    'CGDEAG',
    'SPDEAG',
    'NTAEAG',
    'APMEAG',
    'BMMEAG',
    'FTAEAG',
    'SDDVAG',
    'FDBVAG',
    'MBMGAG',
    'HMMGAG',
    'APMGAG',
    'ENMGAG',
    'PPMGAG',
    'NEMGAG',
    'WAMIAG',
    'CHMIAG',
    'FRMIAG',
    'GRMIAG',
    'RSMIAG',
    'SPMIAG',
    'BCMLAG',
    'BTMLAG',
    'MUMLAG',
    'ZYMLAG',
    'HAMMAG',
    'EUMOAG',
    'WSMOAG',
    'PSMOAG',
    'ISMOAG',
    'HSMOAG',
    'PEMOAG',
    'ECMOAG',
    'DTPOAG',
    'SOMOAG',
    'PEBOAG',
    'LBMOAG',
    'EDMOAG',
    'CPPPAG',
    'STMSAG',
    'RSMSAG',
    'GGMSAG',
    'ICMSAG',
    'MMMSAG',
    'PHMSAG',
    'ORMSAG',
    'GLMSAG',
    'CHMSAG',
    'SUBTAG',
    'SHBTAG',
    'SUMTAG',
    'SHMTAG',
    'LWMWAG',
    'BCCBAG',
    'BCCGAG',
    'AERGAG',
    'AMRGAG',
    'ENRGAG',
    'HMRGAG',
    'PPRGAG',
    'PHRGAG',
    'ARRAAG',
    'BNRAAG',
    'ENRAAG',
    'ELRAAG',
    'FARAAG',
    'HNRAAG',
    'HTRAAG',
    'KMRAAG',
    'LNRAAG',
    'MLRAAG',
    'MRRAAG',
    'PRRAAG',
    'PYRAAG',
    'PNRAAG',
    'SKRAAG',
    'URRAAG',
    'CMRCAG',
    'ACREAG',
    'AMREAG',
    'APREAG',
    'BMREAG',
    'CHREAG',
    'CEREAG',
    'COREAG',
    'EEREAG',
    'ELREAG',
    'MEREAG',
    'NTREAG',
    'PKREAG',
    'SSRIAG',
    'WARIAG',
    'LWRWAG',
    'BCRLAG',
    'BYRLAG',
    'BTRLAG',
    'MURLAG',
    'WLRLAG',
    'ZYRLAG',
    'BARMAG',
    'ATRDAG',
    'BCRDAG',
    'CPRDAG',
    'ECRDAG',
    'FMRDAG',
    'MBRDAG',
    'NSRDAG',
    'PDRDAG',
    'PHRDAG',
    'PIRDAG',
    'PLRDAG',
    'PYRDAG',
    'TBRDAG',
    'CHRSAG',
    'CSRSAG',
    'GGRSAG',
    'GLRSAG',
    'MMRSAG',
    'ORRSAG',
    'PHRSAG',
    'RSRSAG',
    'STRSAG',
    'ECROAG',
    'EDROAG',
    'HSROAG',
    'HRROAG',
    'ISROAG',
    'LBROAG',
    'MCROAG',
    'PEROAG',
    'PLROAG',
    'PSROAG',
    'PAROAG',
    'SWROAG',
    'SOROAG',
    'WSROAG',
    'SHRTAG',
    'SURTAG',
    'FBEXAG',
    'FCSBUG',
    'FGEXAG',
    'FGUXAG',
    'SBEXAG',
    'SCSBUG',
    'SGHGEG',
    'SCGGUG',
    'NBEXAG',
    'NGEXAG',
    'PDCDAG',
    'BAABRS',
    'BAAGRS',
    'BSCBBS',
    'BSCBMS',
    'BSCGAS',
    'CHMSAS',
    'CMBBAS',
    'CMBGAS',
    'CMMCAS',
    'DIPBAS',
    'DIPGAS',
    'GGMSAS',
    'GLMSAS',
    'MAPLAS',
    'MCMOAS',
    'MFCXAS',
    'PSMOAS',
    'RSMSAS',
    'SOMOAS',
    'SSSBHS',
    'SSSGHA',
    'SSSGSS',
    'SSSBSS',
    'SSSGHS', 'SWMOAS',
    'SSSBHF',
    'SSSGHF',
    'SSSGSF',
    'SSSBSF',
    'NRBGAG',
    'NRBBAG',
    'CSDWPR',
    'CSDTLY',
    'CSDEDP',
    'CSDCPG',
    'CSDIND',
    'CSDFIS',
    'CSDEFD'

  ];
  nameF = true
  facultyF = true
  enrolF = true
  hallF = true
  feeF = true
  ge_F = true
  mn_F = true
  hall = [
    'AFTAB HALL', 'ALLAMA IQBAL BOARDING HOUSE', 'HADI HASAN HALL', 'MOHAMMAD HABIB HALL',
    'MOHSINUL MULK HALL', 'NADEEM TARIN HALL', 'ROSS MASOOD HALL', 'SIR SHAH SULAIMAN HALL',
    'SIR SYED HALL (NORTH)', 'SIR SYED HALL (SOUTH)', 'SIR ZIAUDDIN HALL', 'VIQARUL MULK HALL', 'DR B.R. AMBEDKAR HALL','NON RESIDENT STUDENTS CENTRE','ABDULLAH HALL(NR)', 'INDIRA GANDHI HALL(NR)', 'SAROJINI NAIDU HALL(NR)', 'BEGUM SULTAN JAHAN HALL(NR)', 'BIBI FATIMA HALL(NR)', 'BEGUM AZZEZUN NISA HALL(NR)','ABDULLAH HALL', 'INDIRA GANDHI HALL', 'SAROJINI NAIDU HALL', 'BEGUM SULTAN JAHAN HALL', 'BIBI FATIMA HALL', 'BEGUM AZZEZUN NISA HALL','ABDULLAH HALL(NR)', 'INDIRA GANDHI HALL(NR)', 'SAROJINI NAIDU HALL(NR)', 'BEGUM SULTAN JAHAN HALL(NR)', 'BIBI FATIMA HALL(NR)', 'BEGUM AZZEZUN NISA HALL(NR)'
  ];
  courseMap: any;
  multiple=[]

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    Parse.serverURL = environment.serverURL;
    if (this.accountService.userValue) {
      this.user = this.accountService.userValue;
    }

    this.route.queryParams.subscribe(params => {

      if (params.obj) {
        const serializedObject = decodeURIComponent(params.obj);
        this.data = JSON.parse(serializedObject);
        // console.log(this.data); // The deserialized object
      }
    });
    
  }

  async ngOnInit(): Promise<void> {



    window.scrollTo(0, 0);
    this.form = this.formBuilder.group({

    
      enrol: ['', Validators.required],

      minor: ['', Validators.required],
      generic: ['', Validators.required],
      // email: ['',Validators.required ],
      passcode: ['',Validators.required ],
      dob: ['', Validators.required],
      // mobile: ['',Validators.required],
      mname: ['', Validators.required],
      fname: ['', Validators.required],
      gender: ['', Validators.required],

      name: ['', Validators.required],

      hall: ['', Validators.required],
      // cCode: ['', Validators.required],

      fee: ['', Validators.required],



      // address: ['', Validators.required],

       c_offered: ['', Validators.required],
      faculty: ['', Validators.required],




    });
    this.eVerifyForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });
    

    // const get = await this.accountService.getDetail(this.data.cCode, this.data.username, 'get','' ,'');
    // this._data=get
    // console.log(this._data)

    
    this.subject = await this.accountService.getsubjects().then();
    this.major = this.subject.MJ
    this.minor = this.subject.MN
    this.ge = this.subject.GE
    this.va = this.subject.VA
    this.vo = this.subject.VO
    const CourseMap = Parse.Object.extend('CourseMap');
    const query = new Parse.Query(CourseMap);
    query.limit(10000);
    this.courseMap = await query.find();
    for (let i = 0; i < this.courseMap.length; i++) {
    //  if (this.courseMap[i].attributes.cType === 'MULTIPLE') {
        this.multiple.push(this.courseMap[i].attributes);
     // }
    }

  }


  // enable(f) {


  //   if (f == 'name') {
  //     this.form.get('name').enable()
  //     this.nameF = false

  //   } else if (f == 'mname') {
  //     this.form.get('mname').enable()
  //     this.mnameF = false

  //   } else if (f == 'fname') {
  //     this.form.get('fname').enable()
  //     this.fnameF = false
  //   } else if (f == 'faculty') {
  //     this.form.get('faculty').enable()
  //     this.facultyF = false

  //   } else if (f == 'enrol') {
  //     this.form.get('enrol').enable()
  //     this.enrolF = false


  //   } else if (f == 'dob') {
  //     this.form.get('dob').enable()
  //     this.dobF = false

  //   } else if (f == 'hall') {
  //     this.form.get('hall').enable()
  //     this.hallF = false

  //   } else if (f == 'gender') {
  //     this.form.get('gender').enable()
  //     this.genderF = false

  //   } else if (f == 'fee') {
  //     this.form.get('fee').enable()
  //     this.feeF = false

  //   } else if (f == 'ge') {
  //     this.form.get('generic').enable()
  //     this.ge_F = false


  //   } else if (f == 'mn') {
  //     this.form.get('minor').enable()
  //     this.mn_F = false
  //   }


  // }
  get s() { return this.form.controls; }

  async update() {
   
    this.submittedd = true;
    this.alertService.clear();
    if (this.form.invalid) {
         //@ts-ignore
    toastr.error("Some Missing Field", "");
      return;
    }
    
    try {
      const flag = await Parse.Cloud.run('updateFypStudentDataViaHelpdesk', {   name: this.form.value.name, fee:this.form.value.fee,mname: this.form.value.mname, fname: this.form.value.fname,cname: this.form.value.c_offered, faculty_no: this.form.value.faculty_no,enrolment: this.form.value.enrol,dob:this.form.value.dob,hall_allot:this.form.value.hall, mn_code: this.form.value.minor, ge_code: this.form.value.generic,mj_code: this.form.value.major,passcode:this.form.value.passcode}, {
        success: function (s) {
          console.log(s)
    
        }, error: function (error) {
      
    
        }
      })
            
      if (flag=='Data updated successfully. Logs have been created.') {
        this.loading = false;
        //@ts-ignore
        toastr.success(flag, "");
        this.router.navigate(['/fyup-register'])
      
    
        
      }else{
        //@ts-ignore
        toastr.error(flag, "");

      }
    
      
    } catch (error) {
    
    
        //@ts-ignore
        toastr.error(error, "");
        
    }
   
    // this.open(modal)
    // const flag = await Parse.Cloud.run('sendHelpdeskOTP', { 'data': {} }, {
    //   success: function (s) {
    //     console.log(s)

    //   }, error: function (error) {

    //   }
    // })
    // this.loading = true;

  }

  get ff() { return this.form1.controls; }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result: any) => {

      this.closeResult = `Closed with: ${result}`;


    }, (reason: any) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });



  }

  getDismissReason(reason: any) {
    return

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  etoken: number;
  get e() { return this.eVerifyForm.controls; }

  passcode
  Otp
  // userData
  // parameterName
  async verifyEmail() {
    this.submittedE=true
    if (this.eVerifyForm.invalid) {
      return;
    }

    // if (this.EmailF === true) {

    //   this.userData = this.form.value.email
    //   this.parameterName = "email"
    // } else if (this.dobF === true) {
    //   this.userData = this.form.value.dob
    //   this.parameterName = "dob"

    // } else if (this.mobileF === true) {

    //   this.userData = this.form.value.mobile
    //   this.parameterName = "mobile"
    // } else if (this.genderF === true) {
    //   this.userData = this.form.value.gender
    //   this.parameterName = "gender"
    // } else if (this.statusF === true) {

    //   this.userData = this.form.value.status
    //   this.parameterName = "status"
    // } else if (this.fnameF === true) {

    //   this.userData = this.form.value.fname
    //   this.parameterName = "fname"
    // } else if (this.mnameF === true) {
    //   this.userData = this.form.value.mname
    //   this.parameterName = "mname"
    // }
    this.Otp = this.eVerifyForm.value.otp
    // this.passcode = this.form.value.passcode

  

   
    
   }

}
