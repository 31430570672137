<div class="card card-custom card-stretch">
  <div class="card-header py-3">
    <div class="card-title flex-column">
      <h3 class="card-label font-weight-bolder text-dark">Student Status List</h3>
    </div>
  </div>
  <div class="card-body">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Roll No</th>
          <th scope="col">Course</th>
          <th scope="col">Verifier Comment</th>
          <th scope="col">Verifier Action</th>
          <th scope="col">Status</th>
          <th scope="col">Verified By</th>
          <!-- <th scope="col">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of moderation, index as i">
          <th scope="row">{{i+1}}</th>
          <td>
            <a href="javascript:void(0);" (click)="popverify(item)" data-toggle="modal" data-target="#popverifyy">{{item.name}}</a>
          </td>
          <td>{{item.roll_no}}</td>
          <td>{{item.code}}</td>
          <td>{{item.moderation}}</td>
          <td>
            <span [ngClass]="getStatusClass(item.vStatus)">{{item.vStatus === 'allowedtopay' ? 'PAYABLE' : item.vStatus
              ===
              'completed' ? 'PROCESS COMPLETED' : item.vStatus
              === 'approved' ? 'APPROVED' : item.vStatus
              === 'rejected' ? 'REJECTED' : item.vStatus
              === 'submitted' ? 'SUBMITTED' : item.vStatus | uppercase}}</span>

          </td>
          <td>
            <span [ngClass]="getStatusClass(item.status)">{{item.status === 'allowedtopay' ? 'PAYABLE' : item.status ===
              'completed' ? 'PROCESS COMPLETED' : item.status
              === 'verified' ? 'VERIFIED' : item.status
              === 'rejected' ? 'REJECTED' : item.status
              === 'submitted' ? 'SUBMITTED' : item.status | uppercase}}</span>

          </td>
          <td style="word-wrap: break-word;min-width: 160px;max-width: 160px;">{{item.verifiedBy}}</td>
          <td>
            <div class="card-toolbar">
              <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">
                <!-- <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="ki ki-bold-more-ver"></i>
                </a> -->
                <!-- <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <ul class="navi navi-hover py-5">
                    <li class="navi-item">
                      <a href="javascript:void(0);" class="navi-link" data-toggle="modal" data-target="#approve"
                        (click)="approvee(item.username, item.code)"
                        *ngIf="item.vStatus === 'approved' || item.vStatus === 'rejected'">
                        <span class="navi-text">Approve</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link" data-toggle="modal" data-target="#reject"
                        *ngIf="item.vStatus === 'approved' || item.vStatus === 'rejected'">
                        <span class="navi-text text-danger" (click)="rejectt(item.username,item.cCode)">Reject</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="javascript:void(0);" class="navi-link" (click)="reset(item.username, item.code)">
                        <span class="navi-text">Reset</span>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal fade" id="reject" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Reason for Rejection/Remark</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Reason</label>
          <div class="col-lg-9 col-xl-6">
            <textarea class="form-control" [(ngModel)]="reason"
              placeholder="Mention the reason for rejection here..."></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="reject(reason)" class="btn btn-primary font-weight-bold">Reject</button>
        <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="approve" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Remarks/Comments</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Reason</label>
          <div class="col-lg-9 col-xl-6">
            <textarea class="form-control" [(ngModel)]="reason" placeholder="Remarks..."></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="approve(reason)"
          class="btn btn-primary font-weight-bold">Approve</button>
        <button type="button" class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
<div class="modal fade" id="popverifyy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{pv.name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div hidden class="row" id="printable">
          <div class="col-lg-12" *ngFor="let doc of pv.uploads, index as j">
            <figure class="figure">
              <img [src]="doc.url" class="figure-img img-fluid rounded">
              <figcaption class="figure-caption">{{doc.type}} | {{doc.label}}</figcaption>
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <table class="table">
              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{{pv.name}}</td>
              </tr>
              <tr>
                <td>Course Name</td>
                <td>:</td>
                <td>{{pv.cCode}}</td>
              </tr>
              <tr>
                <td>Upgradation</td>
                <td>:</td>
                <td>{{pv.upgradation}}</td>
              </tr>
              <tr>
                <td>Roll Number</td>
                <td>:</td>
                <td>{{pv.roll_no}}</td>
              </tr>
            </table>
          </div>
          <div class="col-lg-6">
            <div class="d-flex justify-content-center">
              <div class="p-2" *ngFor="let doc of pv.uploads, index as j">
                <div *ngIf="doc.type === 'Photo' || doc.type === 'Signature'">
                  <a [href]="doc.url" target="_blank">
                    <img class="img-thumbnail" width="130" [src]="doc.url">
                  </a>
                  <span class="label label-info label-pill label-inline mt-2">{{doc.type}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <h5 class="card-title">Preferences</h5>
        <div class="row">
          <div class="col-lg-4" *ngFor="let doc of pv.preferences, index as i">
            <ul class="navi navi-hover navi-link-rounded-lg">
              <li class="navi-item">
                <a class="navi-link">
                  <span class="navi-label mr-2">
                    <span class="label label-warning label-rounded">{{i+1}}</span>
                  </span>
                  <span class="navi-text">{{doc}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <h5 class="card-title">Uploaded Documents</h5>
        <div class="row">
          <div class="col-lg-12" *ngFor="let doc of pv.uploads, index as j">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    {{doc.type}}
                    <small>{{doc.label}}</small>
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <a [href]="doc.url" target="_blank" class="btn btn-primary btn-sm float-right mb-2">View</a>
                <!-- <iframe width="800" height="1000" [src]="doc.url | safe"></iframe> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button *ngIf="pv.status === 'submitted'" type="button" (click)="approvee(pv.username, pv.code)"
          data-toggle="modal" data-target="#approve" class="btn btn-primary font-weight-bold">Approve</button>
        <button *ngIf="pv.status === 'submitted'" type="button" class="btn btn-primary font-weight-bold"
          data-toggle="modal" data-target="#reject" (click)="rejectt(pv.username,pv.cCode)">Reject</button> -->
        <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


















































