<div class="card card-custom card-stretch " style="width:80%;margin-left: 150px;" >
    <div class="card-header py-3">
      <div class="card-title flex-column">
        <div class="form-inline">
            <a class="card-label font-weight-bolder text-dark"  (click)="home()" type="button">Home</a>
            <button   type='button'  class="btn btn-light-danger m-3" data-toggle="modal" 
            data-target="#paper_modal"><i class="fa fa-plus"></i>Add Paper</button>
            <button   type='button' *ngIf="!pho_flag || !sig_flag" class="btn btn-light-danger m-3" data-toggle="modal" 
            data-target="#document_modal"><i class="flaticon-list-2" ></i>Upload</button>
            <button class="btn btn-light-primary m-3"   (click)="getPdfHall()"><span
                *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span><i class="fa fa-download"  aria-hidden="true"></i>Hall Ticket</button>
              <button button class="btn btn-light-primary m-3"     (click)="getPdfRegistration()"><span
                *ngIf="load" class="spinner-border spinner-border-sm mr-1"></span><i class="fa fa-download"    aria-hidden="true"></i>Registration Card</button> 
                <div class="card" style="width: 12rem;" *ngIf="!pho_flag">
            
                    <div class="card-body">
                      <p class="card-text" style="text-shadow: 5px 5px 5px red;">Photo Not Found</p>
                    </div>
                  </div>
           <div class="ml-5"> 
         
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="pho_flag">
            <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
            <a  href={{pho.url}} target="_blank"><div class="symbol-label" style="background-image: url({{pho.url}})"></div>
            </a> 
           <a  href="javascript:void(0)"  class="mt-2" (click)="delete(pho.id)">
            <i class="fa fa-trash"  style="color:red; margin-top:5px" aria-hidden="true"></i>

           </a>
            
        </div>
     
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="sig_flag">
            <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
            <a  href={{sig.url}} target="_blank"><div class="symbol-label" style="background-image: url({{sig.url}})"></div>
            </a> 
            <a  href="javascript:void(0)"  class="mt-2" (click)="delete(sig.id)">
              <i class="fa fa-trash" style="color:red; margin-top:5px" aria-hidden="true"></i>

             </a>
        </div></div>
        <div class="card" style="width: 12rem;" *ngIf="!sig_flag">
          
            <div class="card-body">
              <p class="card-text" style="text-shadow: 5px 5px 5px red;">Sign Not Found</p>
            </div>
          </div>
              
              
          </div>
        
       
      </div>
    </div>
    <div class="card-body py-3">
        <div class='scroll'>
            <table class="table table-striped gy-7 gs-7">
                <thead>
                  <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Course Name</th>
                    <th>Course Code</th>
                    <th>Credit</th>
                    <th>Type</th>
                    <th>Mode</th>
                    <th>Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subject of subjects">
                    <td>{{subject.subtt}}</td>
                    <td>{{subject.subcd}}</td>
                    <td>{{subject.credits}}</td>
                    <td>{{subject.type}}</td>
                    <td>{{subject.mode}}</td>
                    <td> <i style="cursor: pointer;" (click)="deleteSubject(subject.id)"   class="fas fa-trash fs-4  fas-2x me-2 text-danger"></i></td>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-light-danger btn-sm" (click)="submitPapers(subjects)">Submit Papers</button>
          </div>
    
    </div>

      
 
 
 
 
  </div>
 
  

  <div class="modal fade" id="paper_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalSizeSm"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="title" style="text-shadow: 5px 5px 5px red;
        margin-left: 20px;
        ">Add Paper</h1>
        <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button> 
      </div>
      <div class="modal-body">
        <div class="card">
            <div class="card-body">

                

                <hr style="border: 20px;">
                <h3 class="card-label font-weight-bolder text-dark">Select Subject</h3>
                <div class="form-row">
                    <div class="form-group col-md-3">
                      <select class="form-control" [(ngModel)]="params.param1" name="param1">
                        <option value="">Select</option>
                        <option *ngFor="let item of param1">{{item}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-3">
                        <select name="" id="" class="form-control" (change)="paramm2($event)">
                            <option value="">Select</option>
                            <option value="subtt">Subject Name</option>
                            <option value="subcd">Subject Code</option>
                          </select>
                      <!-- <select class="form-control" [(ngModel)]="params.param2.value" name="param2.value">
                        <option value="">Select</option>
                        <option *ngFor="let item of param2">{{item.name}}</option>
                      </select> -->
                    </div>
                    <div class="form-group col-md-3">
                      <input placeholder="Value" type="text" class="form-control" [(ngModel)]="params.param3"
                        name="param3" />
                    </div>
                    <div class="form-group col-md-3">
                      <button [disabled]="fecthing" (click)="submit()" class="btn btn-primary btn-block"> <span
                          *ngIf="fecthing" class="spinner-border spinner-border-sm mr-1"></span> Search </button>
                    </div>
                  </div>
                <!-- <div class="form-inline">
                    <label></label>
                    <select name="" id="" class="form-control form-control-sm" style="width: 200px;">
                        <option value="">Select</option>
                        <option value="equals">Equal</option>
                        <option value="startsWith">StartWith</option>
                       <option value="endsWith">EndWith</option>
                    </select>
                    <label></label>
                    <select name="" id="" class="form-control form-control-sm" style="width: 200px; margin-left: 10px;">
                      <option value="">Select</option>
                      <option value="subtt">Subject Name</option>
                      <option value="subcd">Subject Code</option>
                      
                    </select>
        
                    <div class="form-outline">
                        <input type="search" id="form1" class="form-control"  placeholder="Search" style="width: 200px; height: 40px; margin-left: 10px;" />
                        
                      </div>
                      <button class="btn btn-light-primary" (click)="submit()"><span *ngIf="loading"
                        class="spinner-border spinner-border-sm mr-1" style="margin-left: 1px;"></span>Search</button>
                  </div> -->
                </div>
     </div>
          
     
     
     
     <div class="card card-custom card-stretch">
            <div class="card-body py-3">
          
              <!--begin::table group-->
              <div class="table-responsive">
               
                <table class="table table-striped gy-7 gs-7">
                  <thead>
                    <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                      <!-- <th></th> -->
                      <th>Course Name</th>
                      <th>Course Code</th>
                      <th>Credit</th>
                      <th>Type</th>
                      <th>Mode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
              
                <tr *ngFor="let course of CourseDetails" class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                      <td>
                      
                        {{course.subtt}}
                      </td>
          
                      <td>{{course.subcd}}</td>
                      <td>{{course.credits}}</td>
                      <td>{{course.type}}</td>
                      <td>   
                        <select name="" id="" class="form-control form-control-sm" style="width: 200px; margin-left: 10px;" (change)="selectmode($event)">
                          <option value="">Select Mode</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          
                        </select></td>
                      <td>  
                        <select name="" id="" class="form-control form-control-sm" style="width: 200px; margin-left: 10px;" (change)="subsidary($event)">
                          <option value="">Select Subsidiary/Elective</option>
                          <option value="Main or Subsidary">Main or Subsidary</option>
                        <option value="Compulsory">Compulsory</option>
                        <option value="Open Elective">Open Elective</option>
                        <option value="Backlog">Backlog</option>
                        </select></td>
                       
                         
                      <td><button class="btn btn-light-danger" (click)="addSubject(course)">Add</button>
                              <!-- <pre>{{course | json}}</pre> -->
                            </td>
                    </tr>
          
                  </tbody>
                </table>
              </div>
          
            </div>
          
          </div>
        
      </div>
         </div>
         </div>
  </div>
  

  <div class="modal fade" id="document_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalSizeSm"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="title" style="text-shadow: 5px 5px 5px red;
        margin-left: 20px;
        ">Documents</h1>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="card" style="width:80%; margin-left: 10%;">
          <div class="card-header">
 </div>
          <div class="card-body">
            <div class="card-body pt-2" >
              <!--begin::Item-->
              <div class="d-flex flex-wrap align-items-center mb-10">
                <!--begin::Symbol-->
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="pho_flag">
                  <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
                  <a  href={{pho.url}} target="_blank"><div class="symbol-label" style="background-image: url({{pho.url}})"></div>
                  </a> 
              </div>
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="sig_flag">
                  <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
                  <a  href={{sig.url}} target="_blank"><div class="symbol-label" style="background-image: url({{sig.url}})"></div>
                  </a> 
              </div>
              
              </div>
            </div>
      
      
        
        <form [formGroup]="form" (ngSubmit)="uploadFile()">
          <div class="card-body">
            <h5 class="card-title">Uploaded Documents</h5>
          
            
            <hr>
           
        
      
      
      
            <div class="form-group row">
              <label class="col-3 col-form-label">Select Upload Type</label>
              <div class="col-9">
                <select (change)="typeChange()" class="form-control" [ngClass]="{ 'is-invalid': submitted && ff.type.errors }"
                  formControlName="type">
                  <option value="">Select</option>
                  <option value="Photo">Photo</option>
                  <option value="Signature">Signature</option>
                </select>
                <span *ngIf="submitted && ff.type.errors" class="invalid-feedback">
                  <div *ngIf="ff.type.errors.required">Upload Type is
                    required
                  </div>
                </span>
              </div>
            </div>
            <br>
          
            <br>
            <div class="form-group row">
              <label class="col-3 col-form-label">Upload File</label>
              <div class="col-9">
                <input type="file" (change)="onFileChanged($event)" class="form-control" placeholder="Upload File"
                  [ngClass]="{ 'is-invalid': submitted && ff.list.errors }" formControlName="list" />
                <span *ngIf="submitted && ff.list.errors" class="invalid-feedback">
                  <div *ngIf="ff.list.errors.required">Upload File is
                    required
                  </div>
                  <div *ngIf="ff.list.errors.extension">
                    {{ff.list.errors.extension.message}}
                  </div>
                  <div *ngIf="ff.list.errors.fileSize">
                    {{ff.list.errors.fileSize.message}}
                  </div>
                </span>
                <span class="text-small text-danger">
                  <div>Acceptable File Formats are *.jpg, *.png, *.gif, *.pdf
                  </div>
                  <div>Max File size is 2MB
                  </div>
                </span>
      
              </div>
      
            </div>
      
          </div>
      
          <div class="card-footer">
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-10">
                <button [disabled]="loading" type="submit" class="btn btn-success mr-2" ><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span> Upload</button>
                    <button type="button" class="btn btn-light-primary font-weight-bold" id="close1" data-dismiss="modal" >Close</button>
                  
              </div>
            </div>
          </div>
        </form>
          
                                                  
        
        
           
              
            </div>
         </div>
      </div>
         </div>
         </div>
  </div>