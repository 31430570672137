import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fyup',
  templateUrl: './fyup.component.html',
  styleUrls: ['./fyup.component.less']
})
export class FyupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
