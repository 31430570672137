import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Parse from 'parse';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  isLoadingSubject: any;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_APP_ID);
    Parse.serverURL = environment.serverURL;
  }

  public get userValue(): User {
    return this.userSubject.value;
    console.log(this.userSubject.value);
  }
  // const user1=new User();
  async login(username, password) {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    const data = new User();
    const user = await Parse.User.logIn(username, password);
    data.id = user.id;
    data.token = user.get('sessionToken');
    data.firstName = user.get('firstName');
    data.lastName = user.get('lastName');
    data.username = user.get('username');
    data.usertype = user.get('usertype');
    data.role = user.get('role');
    data.photo = user.get('photo');
    data.from_rollNo = user.get('from_rollNo');
    data.to_rollNo = user.get('to_rollNo');
    data.email = user.get('email');
    data.mobile = user.get('mobile');
    data.cCode = user.get('cCode');
    localStorage.setItem('user', JSON.stringify(data));
    this.userSubject.next(data);
    return data;
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.clear();
    this.userSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  async register(user: User) {
  //  return await this.http.post(`${environment.apiUrl}/users/register`, user);
  }
  getHall(url) {
    return this.http.get(url);
  }

  async getAll() {
    const query = new Parse.Query(Parse.User);
    return await query.find();
  }
  async getDetail(cCode: string, username: any, typo: string, otp:any, passcode:any) {

    
    try {
      // console.log(obj)
      const data = await Parse.Cloud.run('updateUserDataViaOTP', { 'cCode': cCode, 'username':username , 'typo': typo, 'otp':'', 'passcode':'','userData':'' });

      return data;
    } catch (error) {
      alert(error)
    }
   
  }

 async  getFyupCourseList(){
    try {
      // console.log(obj)
      const data = await Parse.Cloud.run('getFyupCourseList', {  });

      return data;
    } catch (error) {
      alert(error)
    }


  }
  
 async  getFyupStudentList(){
    try {
      // console.log(obj)
      const data = await Parse.Cloud.run('getFypStudentData', {  });

      return data;
    } catch (error) {
      alert(error)
    }


  }
  async updateData(cCode: string, username: any, typo: string, otp:any, passcode:any, userData:any ,param:any) {

    
    try {
      // console.log(obj)
      const data = await Parse.Cloud.run('', { 'cCode': cCode, 'username':username , 'typo': typo, 'otp':'', 'passcode':passcode,'updateValue':userData,'updateParam':param });

      return data;
    } catch (error) {
      alert(error)
    }
   
  }
  async getAllVerifeirs(by) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'av', by: by } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }


  
  async findStudent(email) {
    const flag = await Parse.Cloud.run('findStudent', { 'data': { email: email } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getVerifeirs() {
    const query = new Parse.Query(Parse.User);
    query.equalTo('role', 'verifier');
    return await query.find();
  }
  async getAllAdmins() {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'aa' } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllVerifiers() {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'a_v' } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }

  async getAllVerifiedStudents() {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'avs' } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async search(params) {
    const flag = await Parse.Cloud.run('helpdeskEngg', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async librarySearch(params) {
    const flag = await Parse.Cloud.run('verifyStudent', { 'enrolment': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async searchh(params) {
    const flag = await Parse.Cloud.run('helpdeskResult', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async searchResult(params) {
    const flag = await Parse.Cloud.run('getAllFypResults', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }




  async _search(params) {
    const flag = await Parse.Cloud.run('helpdeskFyp', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }

  async _searchEngg(params) {
    const flag = await Parse.Cloud.run('helpdeskEngg', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async eligibleC(params) {
    const flag = await Parse.Cloud.run('eligibleC', { 'data': params }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }

  async getCourseWiseStatus() {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'avs' } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getEligibleCount(cCode) {
    const flag = await Parse.Cloud.run('getEligibleCount', { 'data': { cCode: cCode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllVerifiedDSW(ccode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'dsw_hall', cCode: ccode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getStudent(ccode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'dsw_hall', cCode: ccode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllfyupstudent(){

    const flag = await Parse.Cloud.run('getStudentsFyup', { 'data': {  } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }


  async getStudentsFyup(){
    const flag = await Parse.Cloud.run('getStudentsFyupPrevious', { 'data': {  } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;

  }
  async getsubjects() {
    const data = await Parse.Cloud.run('getFypSubjects', {  }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return data;
  }

  async getAllVerifiedDSW_1(ccode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'dsw', cCode: ccode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllVerifiedUpgradation(ccode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'upgradation', cCode: ccode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllVerifiedAllotment(ccode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'allot', cCode: ccode } }, {
      success: function (s) {
        console.log(s)

      }, error: function (error) {

      }
    })
    return flag;
  }
  async allotHall(hall, id) {
    const flag = await Parse.Cloud.run('save_dsw', { 'data': { id: id, hall: hall } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async cancelAdmission(id, reason) {
    const flag = await Parse.Cloud.run('cancel_admission', { 'data': { id: id, comment: reason } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async openPayment(arr,code) {
    const flag = await Parse.Cloud.run('open_payment', { 'data': { ids: arr,cCode:code } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }

  async Approved_Result(arr,code) {
    const flag = await Parse.Cloud.run('', { 'data': { ids: arr,cCode:code } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }


  async openP(c, b,cron) {
    const flag = await Parse.Cloud.run('openP', { 'data': { code: c, status: b,cron:cron } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async openC(c, b,cron) {
    const flag = await Parse.Cloud.run('openC', { 'data': { code: c, status: b,cron:cron } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async openG(c, b,cron) {
    const flag = await Parse.Cloud.run('openG', { 'data': { code: c, status: b,cron:cron } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async upgradeAdmission(id, choice) {
    const flag = await Parse.Cloud.run('upgrade_admission', { 'data': { id: id, choice: choice } }, {
      success: function (s) {
        console.log(s)
      }, error: function (error) {

      }
    })
    return flag;
  }
  async allot(allotement) {
  
    let confirmAction = confirm("Are you sure to Allot?\n\n"+"Category:"+" "+allotement.category+"\n"+"Nomination:"+" "+allotement.nomination+"\n"+"Selected Choice:"+" "+allotement.selectedChoice);
    if (confirmAction) {
      const flag = await Parse.Cloud.run('allot', { 'data': allotement }, {
        success: function (s) {
          console.log(s)
        }, error: function (error) {
  
        }
      })
      
      return flag;
     
    } else {
      window.location.reload()
    
    }
   
  }
  async getAllAssignedStudents(from, to, cCode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'aas', from: from, to: to, cCode: cCode } }, {
      success: function (s) {

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllModerationStudents(from, to, cCode) {
    const flag = await Parse.Cloud.run('getU', { 'data': { typo: 'ams', from: from, to: to, cCode: cCode } }, {
      success: function (s) {

      }, error: function (error) {

      }
    })
    return flag;
  }
  async getAllSupport() {
    const Support = Parse.Object.extend('Support');
    const query = new Parse.Query(Support);
    query.equalTo('status', 'open');
    query.limit(99999);
    return await query.find();
  }

  async getById(id: string) {
    const query = new Parse.Query(Parse.User);
    query.equalTo('username', id);
    return await query.find();
  }
  getOutlet(id: string) {
    const Outlet = Parse.Object.extend('Outlet');
    const q = new Parse.Query(Outlet);
    q.equalTo('outletid', id);
    return q.first();
  }

  update(id, params) {
    // return this.http.put(`${environment.apiUrl}/users/${id}`, params)
    //   .pipe(map(x => {
    //     // update stored user if the logged in user updated their own record
    //     if (id == this.userValue.id) {
    //       // update local storage
    //       const user = { ...this.userValue, ...params };
    //       localStorage.setItem('user', JSON.stringify(user));

    //       // publish updated user to subscribers
    //       this.userSubject.next(user);
    //     }
    //     return x;
    //   }));
  }

  delete(id: string) {
    // return this.http.delete(`${environment.apiUrl}/users/${id}`)
    //   .pipe(map(x => {
    //     // auto logout if the logged in user deleted their own record
    //     if (id == this.userValue.id) {
    //       this.logout();
    //     }
    //     return x;
    //   }));
  }

  async getEligibleFyp(){
    const details = await Parse.Cloud.run('getEligibleFypCourseVoc', { 'data': {} }, {
  
    })
    return details
  
  
  
    }



    async getEligiblevac(){
      const details = await Parse.Cloud.run('getEligibleFypCourseVac', { 'data': {} }, {
    
      })
      return details
    
    
    
      }
      async registrationPapersData(id: string, obj: any, typo: string, type:string,enrolment:any) {

    
        try {
          // console.log(obj)
          const data = await Parse.Cloud.run('registrationPapersDataFyp_helpdesk', { 'data': obj, 'id': id, 'typo': typo ,'type':type,'enrolment':enrolment });    
          return data;
        } catch (error) {
          alert(error)
        }
       
      }
      async registrationPapersDataEngg(id: string, obj: any, typo: string, type:string,enrolment:any) {

    
        try {
          // console.log(obj)
          const data = await Parse.Cloud.run('registrationPapersDataEngg', { 'data': obj, 'id': id, 'typo': typo ,'type':type,'enrolment':enrolment });    
          return data;
        } catch (error) {
          alert(error)
        }
       
      }
    

      async search_papers(Compare: string, CompareWith: string, search: string) {
        const CourseDetails = await Parse.Cloud.run('searchPapersEngg', { 'data': { param2: Compare, param1: CompareWith, param3: search } }, {});
        return CourseDetails;
      }

      // async get_particulars() {

      //   const details = await Parse.Cloud.run('getParticulars_Registration', { 'data': {} }, {
    
      //   })
      //   return details
    
      // }


      
 async getdata(enrolment:any){
  console.log(enrolment)

  const data=await Parse.Cloud.run('submitEligibleFypCoursemajor_helpdesk', { 'enrolment':enrolment });
  return data

 }



 //engineering module service

 async get_particulars() {

  const details = await Parse.Cloud.run('getParticulars_Registration', { 'data': {} }, {

  })
  return details

}


































}
