import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Parse from 'parse';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

@Injectable({ providedIn: 'root' })
export class SocialService {
    private userSubject: BehaviorSubject<User>;
    // private userSubject: BehaviorSubject<SocialUser>;
    // public user: Observable<SocialUser>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        // this.userSubject = new BehaviorSubject<SocialUser>(JSON.parse(localStorage.getItem('socialUser')));
        // this.user = this.userSubject.asObservable();
        Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
        Parse.serverURL = environment.serverURL;
    }

    // tslint:disable-next-line: contextual-lifecycle
    // ngOnInit() {
    //     this.authService.authState.subscribe((user) => {
    //         console.log(user);
    //         localStorage.setItem('user', JSON.stringify(user));
    //         this.userSubject.next(user);
    //     });
    // }

    public get userValue(): User {
        return this.userSubject.value;
    }
    //  signInWithGoogle() {
    //     const data = new User();
    //     localStorage.removeItem('user');
    //     this.userSubject.next(null);
    //     this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then();
    // //    this.authService.authState.subscribe((user) => {
    //         this.authService.authState.subscribe((user) => {


    //                     data.token = user.authToken;
    //                     data.firstName = user.firstName;
    //                     data.lastName = user.lastName;
    //                     data.username = user.email;
    //                     data.id = user.id;
    //                     console.log(user);
    //                     console.log(data);
    //                     localStorage.setItem('socialUser', JSON.stringify(user));
    //                     this.userSubject.next(user);
    //                     // this.isSocialLogin = true;
    //                     // this.router.navigate([this.returnUrl]);
    //                     // console.log(this.returnUrl);

    //                    //return data;
    //         });
    //         // console.log(user);
    //         // localStorage.setItem('socialUser', JSON.stringify(user));
    //         // this.userSubject.next(user);

    //    // });
    //     return data;
    // }

    async signInWithG() {
        const data = new User();
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then();
        this.authService.authState.subscribe(async (user) => {
            const query = new Parse.Query(Parse.User);
            query.equalTo('username', user.email);
            const result = await query.find();
            if (!result) {
                const data = new User();
                data.token = user.authToken;
                data.firstName = user.firstName;
                data.lastName = user.lastName;
                data.username = user.email;
                data.id = user.id;
                const parseUser = new Parse.User();
                parseUser.linkWith('facebook', { authData: user });
                localStorage.setItem('user', JSON.stringify(data));
                this.userSubject.next(data);
            } else {
                const puser = new Parse.User();
                puser.set('firstName', user.firstName);
                puser.set('lastName', user.lastName);
                puser.set('username', user.email);
                puser.save();
                const data = new User();
                data.token = user.authToken;
                data.firstName = user.firstName;
                data.lastName = user.lastName;
                data.username = user.email;
                data.id = user.id;
                localStorage.setItem('user', JSON.stringify(data));
                this.userSubject.next(data);
            }
        });
        return data;
    }

    async signInWithF() {
        const data = new User();
        localStorage.removeItem('user');
        this.userSubject.next(null);
        await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
        this.authService.authState.subscribe((user) => {
            console.log(user);
            data.token = user.authToken;
            data.firstName = user.firstName;
            data.lastName = user.lastName;
            data.username = user.email;
            data.id = user.id;
            localStorage.setItem('user', JSON.stringify(data));
            this.userSubject.next(data);
        });
        return data;
    }

    signOut(): void {
        this.authService.signOut();
    }
}