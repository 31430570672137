import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { RegistrationPaperComponent } from './registration-paper/registration-paper.component';
import { AuthGuard, DSWGuard, FinanceGuard, SuperAdminGuard, VerifierGuard, HelpDeskGuard } from './_helpers';
import { AdminGuard } from './_helpers/admin.guard';
import { DeanGuard } from './_helpers/dean.guard';
import { ExecutiveGuard } from './_helpers/executive.guard';
import { SupportGuard } from './_helpers/support.guard';
import { EngineeringModule } from './engineering/engineering.module';
import { EngineeringGuard } from './_helpers/engineering.guard';
import { AddpaperComponent } from './engineering/addpaper/addpaper.component';
import { AddPaperComponent } from './admin/add-paper/add-paper.component';
import { PaperComponent } from './admin/paper/paper.component';
import { HelpdeskEditComponent } from './helpdesk/helpdesk-edit/helpdesk-edit.component';
import { FyupModule } from './fyup/fyup.module';
import { FyupGuard } from './_helpers/fyup.guard';
import { RectifierGuard } from './_helpers/rectifier.guard';
import { FyupRegisterGuard } from './_helpers/fyup-register.guard';
import { FyupEditComponent } from './FYUP-register/fyup-edit/fyup-edit.component';
import { LibraryGuard } from './_helpers/library.guard';




const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfiledModule);
const superadminModule = () => import('./superadmin/superadmin.module').then(x => x.SuperAdminModule);
const engineering = () => import('./engineering/engineering.module').then(x => x.EngineeringModule);
const fyup_regester = () => import('./FYUP-register/fyup-register.module').then(x => x.FYUPRegisterModule);

const fyup = () => import('./fyup/fyup.module').then(x => x.FyupModule);
const verifierModule = () => import('./verifier/verifier.module').then(x => x.VerifierModule);
const dswModule = () => import('./dsw/dsw.module').then(x => x.DSWModule);
const financeModule = () => import('./finance/finance.module').then(x => x.FinanceModule);
const deanModule = () => import('./dean/dean.module').then(x => x.DeanModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const reprtsModule = () => import('./reports/reports.module').then(x => x.ReportsModule);
const executiveModule = () => import('./executive/executive.module').then(x => x.ExecutiveModule);
const helpDeskModule = () => import('./helpdesk/helpdesk.module').then(x => x.HelpDeskModule);
const rectifierModule = () => import('./rectifier/rectifier.module').then(x => x.RectifierModule);
const LibraryModule = () => import('./library/library.module').then(x => x.LibraryModule);

const resultModule = () => import('./result/result.module').then(x => x.ResultModule);
const result_verifier_Module = () => import('./result-verifier/result-verifier.module').then(x => x.ResultVerifierModule);



const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'superadmin', loadChildren: superadminModule, canActivate: [SuperAdminGuard] },
  { path: 'reports', loadChildren: reprtsModule, canActivate: [SupportGuard] },
  { path: 'verifier', loadChildren: verifierModule, canActivate: [VerifierGuard] },
  { path: 'engineer', loadChildren: engineering ,canActivate:[EngineeringGuard]},
  { path: 'dsw', loadChildren: dswModule, canActivate: [DSWGuard] },
  { path: 'helpdesk', loadChildren: helpDeskModule, canActivate: [HelpDeskGuard] },
  { path: 'rectifier', loadChildren: rectifierModule, canActivate: [RectifierGuard] },
  { path: 'library', loadChildren: LibraryModule, canActivate: [LibraryGuard] },

  { path: 'result-admin', loadChildren: resultModule },
  { path: 'result-verifier', loadChildren: result_verifier_Module},
  


  { path: 'dean', loadChildren: deanModule, canActivate: [DeanGuard] },
  { path: 'finance', loadChildren: financeModule, canActivate: [FinanceGuard] },
  { path: 'fyup-register', loadChildren:fyup_regester,canActivate:[FyupRegisterGuard] },

  { path: 'executive', loadChildren: executiveModule, canActivate: [AdminGuard] },
  { path: 'fyup', loadChildren: fyup, canActivate: [FyupGuard] },
  { path: 'admin', loadChildren: adminModule, canActivate: [AdminGuard] },
  { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'registration/:id', component:RegistrationPaperComponent,canActivate: [HelpDeskGuard] },
  { path: "helpdeskedit", component: HelpdeskEditComponent },
  { path: "Fyupedit", component:FyupEditComponent ,canActivate:[FyupRegisterGuard]},

  {path: 'add-paper/:id', component: AddpaperComponent,canActivate:[EngineeringGuard] },
  {path: 'paper/:id', component:PaperComponent ,canActivate:[AdminGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

