<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="d-flex flex-column-fluid">
        <div class="container">
            <div class="row">
                <div class="col-xl-8">
                    <div class="card card-custom gutter-b card-stretch" style="min-height: 600px;">
                        <!-- <div class="card-header border-0 pt-5">
							<div class="font-weight-bolder pull-left">Live Streaming</div>
							<span class="label pulse pulse-success pull-right">{{users.length}} <span
									class="pulse-ring"></span></span>
						</div> -->
                        <div class="card-body d-flex flex-column px-0">
                            <div class="flex-grow-1 card-spacer-x">
                                <a routerLink="live" class="btn btn-primary btn-ic btn-lg btn-block" *ngIf="user.role === 'presenter'">
                                    <i class="fa fa-video"></i> Go Live
                                </a>
                                <a routerLink="upload" class="btn btn-primary btn-ic btn-lg btn-block" *ngIf="user.role==='exhibitor' || user.role==='receptionist'">
                                    <i class="fa fa-upload"></i> Upload
                                </a>
                                <a routerLink="chat" class="btn btn-primary btn-ic btn-lg btn-block" *ngIf="user.role==='exhibitor' || user.role==='receptionist'">
                                    <i class="fa fa-comment"></i> Chat
                                </a>
                                <!-- <div id="localCallId1" class="local1"></div>
								<div id="localCallId2" class="local2"></div>
								<div class="btn-group btn-block mr-2" role="group" aria-label="...">
									<button type="button" class="btn btn-outline-secondary btn-icon"
										(click)="startVideo()" title="Start Video">
										<i class="la la-video"></i>
									</button>
									<button type="button" class="btn btn-outline-secondary btn-icon"
										(click)="stopVideo()" title="Stop Video">
										<i class="la la-video-slash"></i>
									</button>
									<button type="button" class="btn btn-outline-secondary btn-icon"
										(click)="startShare()" title="Start Screen Share">
										<i class="la la-desktop"></i>
									</button>
									<button type="button" class="btn btn-outline-secondary btn-icon"
										title="Stop Screen Sharing">
										<i class="la la-comment-slash"></i>
									</button>
									<button type="button" class="btn btn-outline-secondary btn-icon"
										title="Mic Turn On">
										<i class="la la-microphone"></i>
									</button>
									<button type="button" class="btn btn-outline-secondary btn-icon"
										title="Mic Turn Off">
										<i class="la la-microphone-slash"></i>
									</button>
								</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="card card-custom gutter-b card-stretch">
                        <div class="card-body">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>