<div class="card card-custom card-stretch">
    <a href="javscript:void(0);"   class="ml-3 mt-2"  title="HOME" routerLink="/helpdesk"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
      </svg></a>
    <div class="card-header py-3">
        <div class="card-title text-center">
            <h3 class="card-label font-weight-bolder text-dark ">Edit Detail</h3>
        </div>
        <div></div>
         
       
        <div class="dropdown dropdown-inline">
            <!-- <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ki ki-bold-more-hor"></i>
            </a> -->
            <!-- <a routerLink="/admin/delete" class="nav-link active" data-toggle="tab">
                <span class="nav-icon"></span>
                <span class="nav-text">View Verifiers</span>
            </a> -->
            <!-- <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul class="navi navi-hover py-5">
                    <li class="navi-item">
                        <a routerLink="/admin/delete" class="navi-link">
                            <span class="navi-icon">
                <i class="flaticon-delete"></i>
              </span>
                            <span class="text-danger">View Verifiers</span>
                        </a>
                    </li>
                </ul>
            </div> -->
        </div>
    </div>
    <div class="form-group row mt-2 ml-3">
    
        <!-- <div class="col-lg-9 col-xl-6">
            <select name=""  class="form-control form-control-sm" style="width: 250px" (change)="selectfield($event)">
                <option value="">Select Mode</option>
                <option value="M">Mobile</option>
                <option value="D">Date of Birth</option>
                <option value="E">Email</option>
                <option value="MN">Mother's Name</option>
                <option value="FN">Father's Name</option>
                <option value="S">Status</option>
                <option value="G">Gender</option>
                
                
              </select>
           
        </div> -->
    </div>
  
    <form class="form" [formGroup]="form" (ngSubmit)="update()">
        <div class="card-body">
         
          <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Student Name</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg " type="text"   formControlName="name"  [ngModel]="data.name" [ngClass]="{ 'is-invalid': submittedd && s.name.errors }" />
                <div *ngIf="submittedd && s.name.errors" class="invalid-feedback">
                    <small *ngIf="s.name.errors.required" class="text-small text-danger">Student Name is
          required</small>
                </div>
            </div>
            <!-- <a class="btn btn-primary btn-sm"  *ngIf="nameF"  href="javascript:void(0)" (click)="enable('name')">Enable</a> -->
        </div>
        <div class="form-group row" >
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Mother's Name</label>
          <div class="col-lg-9 col-xl-6">
              <input class="form-control form-control-lg " type="text"   formControlName="mname" [ngModel]="data.mname"  [ngClass]="{ 'is-invalid': submittedd && s.mname.errors }" />
              <div *ngIf="submittedd && s.mname.errors" class="invalid-feedback">
                  <small *ngIf="s.mname.errors.required" class="text-small text-danger">MOther's Name is
        required</small>
              </div>
          </div>
          <!-- <a class="btn btn-primary btn-sm"  *ngIf="mnameF"   href="javascript:void(0)" (click)="enable('mname')">Enable</a> -->
      </div>
      <div class="form-group row" >
        <label class="col-xl-3 col-lg-3 col-form-label text-right">Father's Name</label>
        <div class="col-lg-9 col-xl-6">
            <input class="form-control form-control-lg " type="text"   formControlName="fname" [ngModel]="data.fname" [ngClass]="{ 'is-invalid': submittedd && s.fname.errors }" />
            <div *ngIf="submittedd && s.fname.errors" class="invalid-feedback">
                <small *ngIf="s.fname.errors.required" class="text-small text-danger">Father's Name is
      required</small>
            </div>
        </div>
        <!-- <a class="btn btn-primary btn-sm"  *ngIf="fnameF" href="javascript:void(0)" (click)="enable('fname')">Enable</a> -->
    </div>
        <!-- <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Email</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text"   formControlName="email" [ngClass]="{ 'is-invalid': submittedd && s.email.errors }" />
                <div *ngIf="submittedd && s.email.errors" class="invalid-feedback">
                    <small *ngIf="s.email.errors.required" class="text-small text-danger">Email is
          required</small>
                </div>
            </div>
        </div> -->
        <!-- <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text"   formControlName="mobile" [ngClass]="{ 'is-invalid': submittedd && s.mobile.errors }" />
                <div *ngIf="submittedd && s.mobile.errors" class="invalid-feedback">
                    <small *ngIf="s.mobile.errors.required" class="text-small text-danger">Mobile is
          required</small>
                </div>
            </div>
        </div> -->

        <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Faculty No</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg " type="text"  formControlName="faculty" [ngModel]="data.faculty_no" [ngClass]="{ 'is-invalid': submittedd && s.faculty.errors }" />
                <div *ngIf="submittedd && s.faculty.errors" class="invalid-feedback">
                    <small *ngIf="s.faculty.errors.required" class="text-small text-danger"> Faculty No is
          required</small>
                </div>
            </div>
            <!-- <a class="btn btn-primary btn-sm"  *ngIf="facultyF" href="javascript:void(0)" (click)="enable('faculty')">Enable</a> -->
        </div>
        <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Enrolment</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control  " type="text"  disabled formControlName="enrol" [ngModel]="data.enrolment" [ngClass]="{ 'is-invalid': submittedd && s.enrol.errors }" />
                <div *ngIf="submittedd && s.enrol.errors" class="invalid-feedback">
                    <small *ngIf="s.enrol.errors.required" class="text-small text-danger">Enrolment is
          required</small>
                </div>
                <small  class="text-small text-danger"> Enrolment Number Can not be Updated</small>
            </div>
           
        </div>
        <div class="form-group row" >
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Date of Birth</label>
          <div class="col-lg-9 col-xl-6">
              <input  class="form-control form-control-lg form-control-solid"
              type="date"
              placeholder="dob"

              class="form-control"
              name="dob"
              formControlName="dob"
              [ngModel]="data.dob| date:'yyyy-MM-dd'"
              
               
              
              [ngClass]="{ 'is-invalid': submittedd && s.dob.errors }"
            />
              
              <div *ngIf="submittedd && s.dob.errors" class="invalid-feedback">
                  <small *ngIf="s.dob.errors.required" class="text-small text-danger">DOB is
        required</small>
              </div>
          </div>
          <!-- <a class="btn btn-primary btn-sm" *ngIf="dobF"  href="javascript:void(0)" (click)="enable('dob')">Enable</a> -->
      </div>
      <div class="form-group row" >
        <label class="col-xl-3 col-lg-3 col-form-label text-right">Alloted Hall</label>
        <div class="col-lg-9 col-xl-6">
            <select class="form-control form-control-lg "  formControlName="hall" [ngModel]="data.hall_allot" [ngClass]="{ 'is-invalid': submittedd && s.hall.errors }">
    <option value="">Select</option>
    <option [value]="item" *ngFor="let item of hall">{{item}}</option>
  </select>
            <div *ngIf="submittedd && s.hall.errors" class="invalid-feedback">
                <small *ngIf="s.hall.errors.required" class="text-small text-danger"> Hall is
      required</small>
            </div>
        </div>
        <!-- <a class="btn btn-primary btn-sm" *ngIf="hallF"  href="javascript:void(0)" (click)="enable('hall')">Enable</a> -->

    </div>
       
    <div class="form-group row" >
        <label class="col-xl-3 col-lg-3 col-form-label text-right">Course Name</label>
        <div class="col-lg-9 col-xl-6">
          <select class="form-control form-control-lg "  formControlName="c_offered" [ngModel]="data.cname" [ngClass]="{ 'is-invalid': submittedd && s.c_offered.errors }">
            <option value="">Select</option>
            <option [value]="item.cName" *ngFor="let item of multiple"> {{item.cName}}   [{{item.cCode}}] </option>
          </select>
            <div *ngIf="submittedd && s.c_offered.errors" class="invalid-feedback">
                <small *ngIf="s.c_offered.errors.required" class="text-small text-danger">Course Name  is
      required</small>
            </div>
        </div>
    </div>
        <!-- <div class="form-group row" >
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Course Code</label>
            <div class="col-lg-9 col-xl-6">
                <select class="form-control form-control-lg form-control-solid"  formControlName="cCode"   [ngClass]="{ 'is-invalid': submittedd && s.cCode.errors }">
        <option value="">Select</option>
        <option [value]="item" *ngFor="let item of cCodes">{{item}}</option>
      </select>
                <div *ngIf="submittedd && s.cCode.errors" class="invalid-feedback">
                    <small *ngIf="s.cCode.errors.required" class="text-small text-danger">Course Code is
          required</small>
                </div>
            </div>
        </div> -->
     
       
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right">Fee</label>
                <div class="col-lg-9 col-xl-6">
                    <input class="form-control form-control-lg " type="text"  formControlName="fee" [ngModel]="data.fee"  [ngClass]="{ 'is-invalid': submittedd && s.fee.errors }" />
                    <div *ngIf="submittedd && s.fee.errors" class="invalid-feedback">
                        <small *ngIf="s.fee.errors.required" class="text-small text-danger">Fee is
              required</small>
                    </div>
                </div>
                <!-- <button class="btn btn-primary btn-sm"  *ngIf="feeF" href="javascript:void(0)" (click)="enable('fee')">Enable</button> -->
            </div>
            <div class="form-group row" >
                <label class="col-xl-3 col-lg-3 col-form-label text-right">Gender</label>
                <div class="col-lg-9 col-xl-6">
                    <select class="form-control form-control-lg " [ngModel]="data.gender" formControlName="gender" [ngClass]="{ 'is-invalid': submittedd && s.gender.errors }">
            <option value="">Select</option>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </select>
                    <div *ngIf="submittedd && s.gender.errors" class="invalid-feedback">
                        <small *ngIf="s.gender.errors.required" class="text-small text-danger">Gender is
              required</small>
                    </div>
                </div>
                <!-- <button class="btn btn-primary btn-sm"  *ngIf="genderF" href="javascript:void(0)" (click)="enable('gender')">Enable</button> -->
            </div>
        
       
      
   
       
            <!-- <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"> Major Subject code</label>
                <div class="col-lg-9 col-xl-6">
                    <select class="form-control form-control-lg form-control-solid"  formControlName="major"  [ngModel]="data.mj_code"  [ngClass]="{ 'is-invalid': submittedd && s.major.errors }">
            <option value="">Select</option>
            <option [value]="item" *ngFor="let item of major">{{item}}</option>
          </select>
                    <div *ngIf="submittedd && s.major.errors" class="invalid-feedback">
                        <small *ngIf="s.major.errors.required" class="text-small text-danger">Major Code is
              required</small>
                    </div>
                </div>
            </div> -->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right">Minor Subject code</label>
                <div class="col-lg-9 col-xl-6">
                    <select class="form-control form-control-lg "  formControlName="minor"   [ngModel]="data.mn_code"  [ngClass]="{ 'is-invalid': submittedd && s.minor.errors }">
            <option value="">Select</option>
            <option [value]="item" *ngFor="let item of minor ">{{item}}</option>
          </select>
                    <div *ngIf="submittedd && s.minor.errors" class="invalid-feedback">
                        <small *ngIf="s.minor.errors.required" class="text-small text-danger">Minor Code is
              required</small>
                    </div>
                </div>
                <!-- <button class="btn btn-primary btn-sm"  *ngIf="mn_F" href="javascript:void(0)" (click)="enable('mn')">Enable</button> -->
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Generic Subject code</label>
              <div class="col-lg-9 col-xl-6">
                  <select class="form-control form-control-lg "   formControlName="generic"  [ngModel]="data.ge_code"  [ngClass]="{ 'is-invalid': submittedd && s.generic.errors }">
          <option value="">Select</option>
          <option [value]="item" *ngFor="let item of ge">{{item}}</option>
        </select>
                  <div *ngIf="submittedd && s.generic.errors" class="invalid-feedback">
                      <small *ngIf="s.generic.errors.required" class="text-small text-danger">Generic Code is
            required</small>
                  </div>
              </div>
              <!-- <button class="btn btn-primary btn-sm"  *ngIf="ge_F" href="javascript:void(0)" (click)="enable('ge')">Enable</button> -->
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder  text-danger text-right">Passcode</label>
            <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg " type="text" Placeholder="PassCode" formControlName="passcode" [ngClass]="{ 'is-invalid': submittedd && s.passcode.errors }" />
                <div *ngIf="submittedd && s.passcode.errors" class="invalid-feedback">
                    <small *ngIf="s.passcode.errors.required" class="text-small text-danger">Passcode is
          required</small>
                </div>
            </div>
        </div>
            <!-- <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right">Vocational Subject code</label>
                <div class="col-lg-9 col-xl-6">
                    <select class="form-control form-control-lg form-control-solid"  formControlName="voc"  [ngModel]="data.vo_code" [ngClass]="{ 'is-invalid': submittedd && s.voc.errors }">
            <option value="">Select</option>
            <option [value]="item" *ngFor="let item of vo">{{item}}</option>
          </select>
                    <div *ngIf="submittedd && s.voc.errors" class="invalid-feedback">
                        <small *ngIf="s.voc.errors.required" class="text-small text-danger">Voc Code is
              required</small>
                    </div>
                </div>
            </div> -->
            <!-- <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right">Value Added Subject code</label>
                <div class="col-lg-9 col-xl-6">
                    <select class="form-control form-control-lg form-control-solid"  formControlName="vac" [ngClass]="{ 'is-invalid': submittedd && s.vac.errors }">
            <option value="">Select</option>
            <option [value]="item" *ngFor="let item of va">{{item}}</option>
          </select>
                    <div *ngIf="submittedd && s.vac.errors" class="invalid-feedback">
                        <small *ngIf="s.vac.errors.required" class="text-small text-danger">Vac Code is
              required</small>
                    </div>
                </div>
            </div> -->
    
         
    
         
      
        
     
     
          
       
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-lg-9 ml-lg-auto">
                    <button [disabled]="loading" type="submit" class="btn btn-primary mr-2"><span *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"></span> Update</button>
                    <!-- <button type="reset" class="btn btn-secondary">Reset</button> -->
                </div>
            </div>
        </div>
    </form>
  
</div>


<ng-template   #mymodal let-modal >
    <div  id="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
  <div class="modal-header" >
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" id="ebtnclose" class="close" data-dismiss="modal"  (click)="modal.dismiss('Cross click')" aria-label="Close">
          <i class="fas fa-window-close"></i>
        </button>
  
    </div>
    <!-- Modal body begin  -->
  <!-- <form [formGroup]="eVerifyForm" (ngSubmit)="verifyEmail()">
      <div class="modal-body">
        <div class="form-group">
          <label for="username">Enter OTP</label>
          <input type="text" formControlName="otp" class="form-control" placeholder="Enter OTP"
            [ngClass]="{ 'is-invalid': submittedE && e.otp.errors }" />
          <small class="text-warning">Enter OTP sent to your Email which is valid for 10
            minutes</small>
          <div *ngIf="submittedE && e.otp.errors" class="invalid-feedback">
            <div *ngIf="e.otp.errors.required">OTP is required</div>
          </div>
        </div>
      </div>
      <div>
        <div class="modal-footer">
        <button [disabled]="loadingE" class="btn btn-light-primary"> 
          <span *ngIf="loadingE" class="spinner-border spinner-border-sm mr-1"></span>
          Verify & Submit
        </button>
      </div>
      </div>
     
     </form>
      -->
   </div>  
  

  

   <!-- MOdal body end -->
  <!-- modal footer begin -->
   
    <!-- modal footer end -->
     
    
  </ng-template>