import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { AccountService } from '@app/_services';
@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css'],
})
export class HomeComponent implements OnInit {
  user: User;

  constructor(private accountService: AccountService, private router: Router,) {
    if (this.accountService.userValue) {
      this.user = this.accountService.userValue;
    }
  }
  public async ngOnInit() {
    if(this.user.role === 'superadmin'){
      this.router.navigate(['/superadmin']);
    } else if(this.user.role === 'verifier'){
      this.router.navigate(['/verifier']);
    }
  }
}
