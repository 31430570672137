
//import { AccountService } from '@app/_services';
export class Chatuser {
    id: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    chatmsg: string;
    chattext = new Array();
}
