import { Component } from '@angular/core';
import { User } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import * as Parse from 'parse';
import { environment } from '@environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent {
  user: User;
  briefcase = new Array();
  fileUrl: any;
  outlet = '';
  support: FormGroup;
  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
  ) {
    this.accountService.user.subscribe(x => this.user = x);
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    Parse.serverURL = environment.serverURL;
  }
  get s() { return this.support.controls; }
  submiteed_s = false;
  loading_s = false;
  onSupport() {
    this.submiteed_s = true;
    this.alertService.clear();
    if (this.support.invalid) {
      return;
    }
    this.loading_s = true;
    try {
      const data = {
        name: this.support.value.name,
        email: this.support.value.email,
        contact: this.support.value.contact,
        message: this.support.value.message,
      };
      Parse.Cloud.run('supportemail', { 'data': data }, {
        success: function (savedApartment) {
          console.log(savedApartment);
        }, error: function (error) {
        }
      });
      this.loading_s = false;
      this.alertService.success('Your query has been submitted successfully. You will soon get response.', { keepAfterRouteChange: true });
      document.getElementById('sup').click();
    } catch (error) {
      this.alertService.error(error);
      this.loading_s = false;
    }
  }
  async ngOnInit(): Promise<void> {

  }
  logout() {
    this.accountService.logout();
  }
}
