<div class="card" style="width:80%; margin-left: 10%;">
    <div class="card-header">

      <div class="dropdown">
        <button class="btn btn-light-primary"  type="button" routerLink="/helpdesk" >
          Home 
        </button>
        <button class="btn btn-light-primary dropdown-toggle ml-3"  type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Menu <span class="spinner-grow spinner-grow-sm" *ngIf="loader" role="status" aria-hidden="true"></span>
          </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="btn-group" role="group" aria-label="Basic example">
          <button class="btn btn-light-danger m-3" (click)="fetch()"  >
            Fetch paper</button>
             <button   type='button' *ngIf="!pho_flag || !sig_flag"  class="btn btn-light-danger m-3" data-toggle="modal" 
            data-target="#document_modal">Upload</button>  
            
            <button class="btn btn-light-primary m-3"   (click)="getPdfHall()"><span *ngIf="loadings"
              class="spinner-border spinner-border-sm  btn-sm mr-1"></span><i class="fa fa-download"  aria-hidden="true"></i>Hall Ticket</button>
            <button button class="btn btn-light-primary m-3"     (click)="getPdfRegistration()"><span *ngIf="load"
              class="spinner-border spinner-border-sm  btn-sm mr-1"></span><i class="fa fa-download"    aria-hidden="true"></i>Registration Card</button> 
        </div>
         
      </div>
      </div>
      <div class="card-body pt-2 float-right" >
        <!--begin::Item-->
        <div class="d-flex flex-wrap align-items-center mb-10">
          <!--begin::Symbol-->
          <div class="card" style="width: 10rem;" *ngIf="!pho_flag">
          
            <div class="card-body">
              <p class="card-text">Photo Not Upload</p>
            </div>
          </div>
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="pho_flag">
            <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
            <a  href={{pho.url}} target="_blank"><div class="symbol-label" style="background-image: url({{pho.url}})"></div>
            </a> 
           <a  href="javascript:void(0)"  class="mt-2" (click)="delete(pho.id)">
            <i class="fa fa-trash"  style="color:red; margin-top:5px" aria-hidden="true"></i>

           </a>
            
        </div>
        <div class="card" style="width: 10rem;" *ngIf="!sig_flag">
          
          <div class="card-body">
            <p class="card-text">Sign Not Upload</p>
          </div>
        </div>
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="sig_flag">
            <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
            <a  href={{sig.url}} target="_blank"><div class="symbol-label" style="background-image: url({{sig.url}})"></div>
            </a> 
            <a  href="javascript:void(0)"  class="mt-2" (click)="delete(sig.id)">
              <i class="fa fa-trash" style="color:red; margin-top:5px" aria-hidden="true"></i>

             </a>
        </div>
        
        </div>
      </div>
     
   
      
     

      
      
      
      
      
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center">
        <span class="text-muted text-center fs-1" *ngIf="subjects.length === 0 && !myCourseLoading">
          Your selected papers goes here, no papers choosen yet...
        </span>
        <div class="d-flex justify-content-center" *ngIf="myCourseLoading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="table-responsive" *ngIf="subjects.length > 0" >
        <table class="table" style="width: 100%;" >
          <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>#</th>
              <th>Course Name</th>
              <th>Course Code</th>
              <th>Credit</th>
              <th>Type</th>
              <th>Mode</th>
              <th  *ngIf="submit_flag">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let subject of subjects let i=index">
           
              <td>{{i+1}}</td>
              <td>{{subject.subtt}}</td>
              <td>{{subject.subcd}}</td>
              <td>{{subject.credits}}</td>
             <td> <span style="width:8rem;font-size: 15px;"  [ngClass]="(getStatusClass(subject.type))! "> {{subject.type}}</span> </td>
              <td>{{subject.mode}}</td>
              <td  *ngIf="submit_flag"class="text-center">
                  <i style="cursor: pointer;" (click)="deleteSubject(subject.id)" *ngIf="!myCourseLoading"  class="fas fa-trash fs-4  fas-2x me-2 text-danger"></i>
                  <i *ngIf="myCourseLoading" class="fas fa-circle-notch fa-spin"></i>
                  
              </td>
             
              
            </tr>
           
          </tbody>
         
          
        </table>
        <button class="btn btn-light-danger btn-sm" (click)="submitPapers(subjects)">Submit Papers</button>
        <!-- <span><button *ngIf="pay_flag" style=" margin-left: 30px;"  (click)="startPayment()"class="btn btn-primary btn-sm">Pay Now</button></span> -->
        <!-- <button class="btn btn-light-danger btn-sm" (click)="Reciept()">Fee Reciept</button> -->
      </div>
      <hr  class="new3">                                              
  
  
   
      <div class="d-flex justify-content-center">
        <span class="text-muted text-center fs-1" *ngIf="allVac.length === 0 && !serachLoading">
          No Data Available
        </span>
        <div class="d-flex justify-content-left" *ngIf="serachLoading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
     
     
   
      <div class="flex flex-col items-center mt-2">
  
  
  
       
        <form   [formGroup]="addForm"  (ngSubmit)="onSubmit()">
          <label class="form-label fw-bolder  fs-6"> Select Value Added Paper</label>
          <select class="form-control " aria-label="Default select example" name="select1"
            formControlName="select1">
            <!-- <option value="">Select Paper</option> -->
            <option [value]="data.subtt" *ngFor="let data of allVac" >{{data.subtt}}</option>
          </select>
  
         
  
       
       
         
         
  
        
          <span>  <button   type="submit" class="btn btn-light-primary btn-sm mt-5"   [disabled]="btn1 === 1"><i class="fa fa-plus"></i>Add Value Added Paper</button></span>
          <marquee class="text-danger"  *ngIf="vac_tag">If you want to add Value Added paper. Kindly delete Value Added paper in the Table</marquee>
        </form>
       
        
      </div>
      <hr  class="new4">
      <div class="flex flex-col items-center mt-2">
  
  
  
       
        <form   [formGroup]="addForm1"  (ngSubmit)="onSubmit1()">
          <label class="form-label fw-bolder  fs-6"> Select Vocational Paper</label>
          <select class="form-control " aria-label="Default select example" name="select2" 
            formControlName="select2">
            <!-- <option value="">Select Paper</option> -->
            <option [value]="data.subtt" *ngFor="let data of allVoc" >{{data.subtt}}</option>
          </select>
  
    
          <span>  <button    [disabled]="btn2 === 1"  type="submit" class="btn btn-light-primary btn-sm mt-5" ><i class="fa fa-plus"></i>Add Vocational Paper</button></span>
          <marquee class="text-danger"  *ngIf="voc_tag">If you want to add Vocational paper. Kindly delete Vocational paper in the Table</marquee>
        </form>
       
        
      </div>
      
      
  
  
  
  
    </div>
  
  </div>








  

  <div class="modal fade" id="document_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalSizeSm"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="title" style="text-shadow: 5px 5px 5px red;
        margin-left: 20px;
        ">Documents</h1>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="card" style="width:80%; margin-left: 10%;">
          <div class="card-header">
 </div>
          <div class="card-body">
            <div class="card-body pt-2" >
              <!--begin::Item-->
              <div class="d-flex flex-wrap align-items-center mb-10">
                <!--begin::Symbol-->
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="pho_flag">
                  <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
                  <a  href={{pho.url}} target="_blank"><div class="symbol-label" style="background-image: url({{pho.url}})"></div>
                  </a> 
              </div>
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0" style="margin-left: 20px;" *ngIf="sig_flag">
                  <!-- <ngx-imageviewer [src]="imageSrc"></ngx-imageviewer> -->
                  <a  href={{sig.url}} target="_blank"><div class="symbol-label" style="background-image: url({{sig.url}})"></div>
                  </a> 
              </div>
              
              </div>
            </div>
      
      
        
        <form [formGroup]="form" (ngSubmit)="uploadFile()">
          <div class="card-body">
            <h5 class="card-title">Uploaded Documents</h5>
          
            
            <hr>
           
        
      
      
      
            <div class="form-group row">
              <label class="col-3 col-form-label">Select Upload Type</label>
              <div class="col-9">
                <select (change)="typeChange()" class="form-control" [ngClass]="{ 'is-invalid': submitted && ff.type.errors }"
                  formControlName="type">
                  <option value="">Select</option>
                  <option value="Photo">Photo</option>
                  <option value="Signature">Signature</option>
                </select>
                <span *ngIf="submitted && ff.type.errors" class="invalid-feedback">
                  <div *ngIf="ff.type.errors.required">Upload Type is
                    required
                  </div>
                </span>
              </div>
            </div>
            <br>
          
            <br>
            <div class="form-group row">
              <label class="col-3 col-form-label">Upload File</label>
              <div class="col-9">
                <input type="file" (change)="onFileChanged($event)" class="form-control" placeholder="Upload File"
                  [ngClass]="{ 'is-invalid': submitted && ff.list.errors }" formControlName="list" />
                <span *ngIf="submitted && ff.list.errors" class="invalid-feedback">
                  <div *ngIf="ff.list.errors.required">Upload File is
                    required
                  </div>
                  <div *ngIf="ff.list.errors.extension">
                    {{ff.list.errors.extension.message}}
                  </div>
                  <div *ngIf="ff.list.errors.fileSize">
                    {{ff.list.errors.fileSize.message}}
                  </div>
                </span>
                <span class="text-small text-danger">
                  <div>Acceptable File Formats are *.jpg, *.png, *.gif, *.pdf
                  </div>
                  <div>Max File size is 2MB
                  </div>
                </span>
      
              </div>
      
            </div>
      
          </div>
      
          <div class="card-footer">
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-10">
                <button [disabled]="loading" type="submit" class="btn btn-success mr-2" ><span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span> Upload</button>
                    <button type="button" class="btn btn-light-primary font-weight-bold" id="close1" data-dismiss="modal" >Close</button>
                  
              </div>
            </div>
          </div>
        </form>
          
                                                  
        
        
           
              
            </div>
         </div>
      </div>
         </div>
         </div>
  </div>